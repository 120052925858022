import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
import "../../../assets/style/style.css"
import logo from "../../../assets/images/Pham logo black text.png"
import { useReactToPrint } from "react-to-print"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  colspan,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { formatDate } from "@fullcalendar/react"
import { formatAmount } from "components/Functoins/functions"

const SupplierPaymentView = props => {
  // const { state } = useLocation()
  // const supplier = state.supplier
  // const project = state.project
  // const { id } = useParams()
  // const pur_id = id

  const { id } = useParams()
  const payment_id = id

  document.title = "Payment Receive"
  const navigate = useNavigate()

  const [pur_id, setPur_id] = useState([])

  const [totalCutBalance, setTotalCutBalance] = useState(0)
  const [totalRBalance, setTotalRBalance] = useState(0)
  const [suppliers, setSuppliers] = useState([])
  const [custunallocated, setCustunallocated] = useState([])
  const [bank, setBank] = useState([])
  const [pay_mode, setPaymode] = useState([])
  const [projects, setProjects] = useState([])

  const [loading, setloading] = useState(false)

  const [supacc, setSuppacc] = useState([])
  const [prjCode, setPrjCode] = useState([])
  const [bankCode, setBankCode] = useState([])
  //Customer Detail
  const [formData, setFormData] = useState({
    supplier_name: "",
    project_name: "",
    address_: "",
    payment_mode: "",
    bank_name: "",
    date_: new Date().toISOString().substr(0, 10),
    bill_ref_no: "",
    bill_pay_by: "",
    pay_amount: "0",
  })

  const [billData, setBillData] = useState([])

  // const handleChange = e => {
  //   const { name, value } = e.target
  //   setFormData(prevData => ({
  //     ...prevData,
  //     [name]: value,
  //   }))

  //   if (name === "pay_amount") {
  //     let payamount = parseFloat(value)
  //     const newBalance = payamount - totalRBalance

  //     if (newBalance >= 0) {
  //       setCustunallocated(newBalance)
  //     } else {
  //       setCustunallocated("0")
  //     }

  //     const updatedFetchData = fetchData.map(row => {
  //       const rBalance = parseFloat(row.balance_)

  //       let totalCal = 0

  //       if (payamount >= rBalance) {
  //         totalCal = rBalance
  //         payamount -= rBalance
  //       } else {
  //         totalCal = parseFloat(payamount)
  //         payamount = 0
  //       }

  //       return {
  //         ...row,
  //         total_cal: totalCal.toFixed(2),
  //       }
  //     })
  //     setfetchData(updatedFetchData)
  //   }
  // }

  //FetcSupplier by ID
  //Fetch Purchase Detail By ID
  const fetchPurchaseData = async id => {
    try {
      setloading(true) // Set loading state to true while fetching data
      const URL = `${apiUrl}/purchases/addPurchases.php`
      const post = new FormData()
      post.append("payment_id", id)
      post.append("app_method", "fecth_purchase_payment_history_by_vo_id")
      const response = await axios.post(URL, post)

      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          const purchasePaymentData = response.data[0]
          setFormData(prevData => ({
            ...prevData,
            supplier_name: purchasePaymentData.supplier_name || "",
            address_: purchasePaymentData.supplier_add || "",
            project_name: purchasePaymentData.project_name || "",
            bank_name: purchasePaymentData.bank_name || "",
            payment_mode: purchasePaymentData.pay_mode || "",
            bill_ref_no: purchasePaymentData.pay_ref_no || "",
            bill_pay_by: purchasePaymentData.pay_by || "",
            date_: purchasePaymentData.pay_date || "",
            pay_amount: purchasePaymentData.total_pay_amount || "",
          }))
          setBillData([...response.data])
        } else {
          setBillData([])
          setFormData(prevData => ({ ...prevData }))
        }
      } else {
        console.log("Error in fetchPurchaseData: Response is not an array")
      }
    } catch (error) {
      console.error("Error fetching purchase data:", error)
    } finally {
      setloading(false) // Finally block ensures that loading state is set to false regardless of success or failure
    }
  }

  useEffect(() => {
    fetchPurchaseData(payment_id)
  }, [payment_id])

  // //Fetch Product Data
  // const initialData2 = [
  //   {
  //     id: "",
  //     purchase_date: "",
  //     purchase_bill_no: "",
  //     purchase_id: "",
  //     due_date: "",
  //     total_amount: "",
  //     balance_: "",
  //     // discount_per: "",
  //     total_cal: "0.00",
  //   },
  // ]
  // const [fetchData, setfetchData] = useState(initialData2)

  // const handleEditF = (id, field, value) => {
  //   setfetchData(prevData =>
  //     prevData.map(item => {
  //       if (item.id === id) {
  //         return {
  //           ...item,
  //           [field]: value,
  //           prd_amount:
  //             field === "prd_qty" || field === "prd_rate"
  //               ? parseFloat(value) * parseFloat(item.prd_rate)
  //               : item.prd_amount,
  //         }
  //       } else {
  //         return item
  //       }
  //     })
  //   )
  // }
  // const handleEditF2 = (id, field, value) => {
  //   setfetchData(prevData =>
  //     prevData.map(item => {
  //       if (item.id === id) {
  //         return {
  //           ...item,
  //           [field]: value,
  //           prd_amount:
  //             field === "prd_qty" || field === "prd_rate"
  //               ? parseFloat(item.prd_qty) * parseFloat(value)
  //               : item.prd_amount,
  //         }
  //       } else {
  //         return item
  //       }
  //     })
  //   )
  // }

  // //Fetch Supplier

  // const fetchSupplierDetail = SuppName => {
  //   const formData2 = {
  //     method: "fetch_supplier",
  //   }
  //   axios
  //     .post(
  //       "${apiUrl}/purchases/suppliers.php",
  //       formData2
  //     )
  //     .then(response => {
  //       if (Array.isArray(response.data)) {
  //         //   setSuppliers(response.data)
  //         const selectedSupplier = response.data.find(
  //           supplier => supplier.bussiness_name === SuppName
  //         )
  //         if (selectedSupplier) {
  //           // setCustunallocated(selectedSupplier.open_balance || "0.00")
  //           // setCustCreditBlnc(selectedSupplier.credit_balance || "0.00")
  //           setFormData(prevData => ({
  //             ...prevData,
  //             address_: selectedSupplier.address_ || "",
  //           }))
  //         } else {
  //           console.log("Error: Suppliers not found")
  //         }
  //       } else {
  //         console.log("Error in fetch suppliers")
  //       }
  //     })
  //     .catch(error => console.error("Error fetching suppliers:", error))
  // }
  // useEffect(() => {
  //   if (formData.supplier_name) {
  //     fetchSupplierDetail(formData.supplier_name)
  //   }
  // }, [formData.supplier_name])

  //Calculation
  useEffect(() => {
    let sumCutBalance = 0
    let sumAmount = 0
    billData.forEach(item => {
      const cutbalance = parseFloat(item.bill_amount) || 0
      const tremainbalance = parseFloat(item.balance_amount) || 0

      sumCutBalance += cutbalance
      sumAmount += tremainbalance
    })

    setTotalCutBalance(sumCutBalance)
    setTotalRBalance(sumAmount)
  }, [billData])

  // //Submit Payment
  // const SubmitPayment = e => {
  //   if (formData.supplier_name === "") {
  //     toastr.error("Supplier is required")
  //   } else if (formData.project_name === "") {
  //     toastr.error("Set Project")
  //   } else if (formData.bank_name === "") {
  //     toastr.error("Select Bank")
  //   } else if (formData.pay_mode === "") {
  //     toastr.error("Set Pay Mode")
  //   } else if (formData.pay_amount === "") {
  //     toastr.error("Set Pay Amount")
  //   } else if (formData.date_ === "") {
  //     toastr.error("Data is Required")
  //   } else {
  //     e.preventDefault()
  //     const URl = "${apiUrl}/purchases/payments.php"
  //     const formdata = new FormData()
  //     //fetch row data
  //     fetchData.forEach(row => {
  //       formdata.append("id[]", row.id)
  //       formdata.append("cutBalanceAmount[]", row.total_cal)
  //     })

  //     //customer data
  //     formdata.append("pur_id", pur_id)
  //     formdata.append("supplier_name", formData.supplier_name)
  //     formdata.append("project_name", formData.project_name)
  //     formdata.append("supplier_address", formData.address_)
  //     formdata.append("purchase_date", formData.date_)
  //     formdata.append("total_pay_amount", formData.pay_amount)
  //     formdata.append("bank_name", formData.bank_name)
  //     formdata.append("pay_mode", formData.payment_mode)
  //     formdata.append("bill_ref_no", formData.bill_ref_no)
  //     formdata.append("unallocated_balance_", custunallocated)
  //     formdata.append("tcut_balancAmount", totalCutBalance)

  //     formdata.append("app_method", "supplier_payment")

  //     axios
  //       .post(URl, formdata)
  //       .then(response => {
  //         if (response.data.trim() === "success") {
  //           toastr.success("Add Successfully")
  //           navigate("/expenses/control/payments")
  //           // console.log(response.data)
  //         } else {
  //           toastr.error(response.data)
  //         }
  //       })
  //       .catch(error => console.log(error))
  //   }
  // }

  const fetchSuppliers = () => {
    const formData2 = {
      method: "fetch_supplier",
    }
    axios
      .post(`${apiUrl}/purchases/suppliers.php`, formData2)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSuppliers(response.data)
          const code = response.data.find(
            item => item.bussiness_name === formData.supplier_name
          )
          if (code) {
            setSuppacc(code.acc_no)
          }
        } else {
          console.log("Error in fetch suppliers")
        }
      })
      .catch(error => console.log(error))
  }

  //Projects
  const fetchProject = () => {
    const data2 = {
      method: "fetch_all_projects",
    }
    axios
      .post(`${apiUrl}/Projects/projects.php`, data2)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
          const code = response.data.find(
            item => item.project_name === formData.project_name
          )
          if (code) {
            setPrjCode(code.project_code)
          }
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Bank
  const fetchBank = () => {
    const data = {
      method: "fetch_bank",
    }
    axios
      .post(`${apiUrl}/BankManagemnt/addbank.php`, data)
      .then(response => {
        if (response.data) {
          setBank(response.data)
          const code = response.data.find(
            item => item.bank_name === formData.bank_name
          )
          if (code) {
            setBankCode(code.bank_code)
          }
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  // //Pay Mode
  // const fetchPayMode = () => {
  //   const URL =
  //     "${apiUrl}/SettingModules/modules.php"
  //   const pm = new FormData()
  //   pm.append("app_method", "fetch_pay_mode")

  //   axios
  //     .post(URL, pm)
  //     .then(response => {
  //       if (response.data) {
  //         setPaymode(response.data)
  //       } else {
  //         console.log("error")
  //         console.log(response.data)
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }

  useEffect(() => {
    fetchSuppliers()
    fetchBank()
    fetchProject()
  }, [formData.supplier_name, formData.project_name, formData.bank_name])

  //Close
  const close = e => {
    e.preventDefault()
    navigate("/expenses/control/payments")
  }

  //Print
  const printRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Supplier Payment</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Card>
              <CardBody>
                <Row className="mb-5">
                  <Col xl={4}>
                    <div className="mb-3">
                      <Label className="control-label" htmlFor="supplier_name">
                        Supplers
                      </Label>
                      <InputGroup>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={e =>
                            handleChangeCustomer(e.target.selectedOptions[0])
                          }
                          name="supplier_name"
                          value={formData.supplier_name}
                          disabled
                        >
                          <option value={formData.supplier_name}>
                            {formData.supplier_name} ({supacc})
                          </option>
                        </select>
                        <div className="input-group-append"></div>
                      </InputGroup>
                    </div>
                    <div className="mb-3">
                      <label>Address</label>
                      <Input
                        className="form-control"
                        type="textarea"
                        placeholder="Address"
                        name="address_"
                        rows="1"
                        value={formData.address_}
                        // onChange={handleChange}
                        disabled
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <Label className="control-label" htmlFor="project_name">
                        Project
                      </Label>
                      <InputGroup>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={e =>
                            handleChangeProject(e.target.selectedOptions[0])
                          }
                          name="project_name"
                          value={formData.project_name}
                          disabled
                        >
                          <option value={formData.project_name}>
                            {formData.project_name} ({prjCode})
                          </option>
                        </select>
                        <div className="input-group-append"></div>
                      </InputGroup>
                    </div>
                  </Col>

                  <Col xl={4}>
                    <div className="mb-3">
                      <label className="" htmlFor="autoSizingSelect">
                        Bank
                      </label>
                      <select
                        defaultValue="0"
                        className="form-select"
                        // onChange={handleChange}
                        name="bank_name"
                        value={formData.bank_name}
                        disabled
                      >
                        <option value={null}>Select bank ...</option>
                        <option value={formData.bank_name}>
                          {formData.bank_name} ({bankCode})
                        </option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label>Date</label>
                      <Input
                        className="form-control"
                        type="date"
                        //   placeholder="Payment Mode"
                        name="date_"
                        value={formData.date_}
                        // onChange={handleChange}
                        disabled
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>Amount</label>
                      <Input
                        className="form-control"
                        type="number"
                        min={0}
                        placeholder="0.00"
                        name="pay_amount"
                        value={formData.pay_amount}
                        // onChange={handleChange}
                        disabled
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="mb-3">
                      <label className="" htmlFor="autoSizingSelet">
                        Payment Mode
                      </label>
                      <select
                        className="form-select"
                        type="text"
                        value={formData.payment_mode}
                        // onChange={handleChange}
                        name="payment_mode"
                        disabled
                      >
                        <option value={formData.payment_mode}>
                          {formData.payment_mode}
                        </option>
                      </select>
                    </div>

                    <Row>
                      <Col xl={6}>
                        <div className="mb-3">
                          <label>Ref No</label>
                          <Input
                            className="form-control"
                            type="text"
                            min={0}
                            placeholder="ref no"
                            name="bill_ref_no"
                            value={formData.bill_ref_no}
                            // onChange={handleChange}
                            disabled
                          ></Input>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="mb-3">
                          <label>Pay By</label>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="name"
                            name="bill_pay_by"
                            value={formData.bill_pay_by}
                            // onChange={handleChange}
                            disabled
                          ></Input>
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <label>Unallocated Balance</label>
                      <Input
                        className="form-control"
                        type="number"
                        disabled
                        min={0}
                        placeholder="0.00"
                        name="unallocated_balance_"
                        value={formatAmount(Number(custunallocated))}
                        // onChange={handleChange}
                      ></Input>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <div>
                    <h6>Outstanding Invoices</h6>
                  </div>
                  <Col xl={12}>
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Bill No</th>
                          <th>Due Date</th>
                          <th>Bill No</th>
                          <th>Original Amount</th>
                          <th>Open Balance</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {billData.map(item => (
                          <tr key={item.id}>
                            <td style={{ display: "none" }}>{item.id}</td>
                            <td>
                              <input
                                type="text"
                                value={item.pay_date}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                rows={2}
                                value={item.bill_id}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.bill_due_date}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.bill_no}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={formatAmount(item.original_amount)}
                                min={1}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                min={1}
                                value={formatAmount(item.balance_amount)}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={formatAmount(item.bill_amount)}
                                disabled
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>

                      <tbody>
                        <thead></thead>
                        <tr
                          style={{
                            borderTop: "1px solid black",
                          }}
                        >
                          <td colSpan={2}>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={formatAmount(totalRBalance)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={formatAmount(totalCutBalance)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xl={4}>
                    <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col xl={8}>
                    <div className="d-flex flex-wrap gap-2 float-end">
                      <Link
                        to="#"
                        onClick={handlePrint}
                        className="btn btn-success  me-2"
                      >
                        <i className="fa fa-print" />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
        <div
          ref={printRef}
          className="paymentPrint"
          style={{ display: "none" }}
        >
          <Row>
            <Col xl={12}>
              <Row>
                <Col xl={6}>
                  <img
                    src={logo}
                    alt="logo-dark"
                    className="logo-dark-element"
                    height="80"
                  />
                </Col>
                <Col xl={6}>
                  <div className="float-end">
                    <h1>
                      <b>Pham Enterprises</b>
                    </h1>
                    <h7>
                      Office -8 Bashir Market Behind Hascol Pump
                      <br /> Near G15 G,T Road ISLAMABAD Pakistan <br />
                      <strong>Phone :</strong> 00923075727380
                    </h7>
                  </div>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col sm="8">
                  <h3>
                    <b>Payments</b>
                  </h3>
                  <address>
                    <strong>{formData.cust_name}</strong>
                    <br />
                    {formData.address_}
                  </address>
                </Col>
                <Col sm="4">
                  <div className="float-end">
                    <address>
                      <strong>Date : </strong>
                      {formData.pay_date}
                    </address>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <table className=" table-bordered border-black mb-0 mt-3">
                    <thead>
                      <tr className="black-head">
                        <th>Date</th>
                        <th>Due Date</th>
                        <th>Bill No</th>
                        <th>Pay By</th>
                        <th>Original Amount</th>
                        <th>Open Balance</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billData.map(item => (
                        <tr key={item.id}>
                          <td style={{ display: "none" }}>{item.id}</td>
                          <td>{item.pay_date}</td>
                          <td>{item.bill_due_date}</td>
                          <td>{item.bill_no}</td>
                          <td>{item.pay_by}</td>
                          <td>{formatAmount(item.original_amount)}</td>
                          <td>{formatAmount(item.balance_amount)}</td>
                          <td>{formatAmount(item.bill_amount)}</td>
                        </tr>
                      ))}
                    </tbody>
                    {formData.advance_amount > 0 ? (
                      <tbody>
                        <tr>
                          <td>{formData.pay_date}</td>
                          <td></td>
                          <td></td>
                          <td>{"0.00"}</td>
                          <td>{formatAmount(formData.advance_amount)}</td>
                          <td>{formatAmount(formData.advance_amount)}</td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody></tbody>
                    )}
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <div className="mt-5 mb-5">
            <Row>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Prepared By</b>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Reviewed By</b>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Approved By</b>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Received By</b>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SupplierPaymentView
