import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { formatAmount } from "components/Functoins/functions"
import logo from "../../../assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  InputGroup,
  Button,
} from "reactstrap"
import "../../../assets/style/style.css"

import { useReactToPrint } from "react-to-print"
import axios from "axios"

const SaleQuotationReport = props => {
  //meta title
  document.title = "Bank Report"

  const [quotation, setQuotation] = useState([])
  const [filterDiv, setFilterdiv] = useState(false)
  const [projects, setProjects] = useState([])
  const [customers, setCustomers] = useState([])
  const [totalamount, setTotalamount] = useState("")
  const [state, setState] = useState({
    cust_name: "",
    project_name: "",
  })

  const handleState = e => {
    const { name, value } = e.target
    setState(prData => ({
      ...prData,
      [name]: value,
    }))
  }

  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Customer
  const fetchCustomers = () => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_customers")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCustomers(response.data)
        } else {
          console.log("Error in fetch customers")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchCustomers()
    fetchProject()
  }, [])

  const handleFilter = () => {
    const URL = `${apiUrl}/sales/orders.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_orders")
    setFilterdiv(true)
    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          let filteredPayments = response.data

          if (state.cust_name && state.project_name) {
            // Filter quotation based on both cust_name and project_name
            filteredPayments = response.data.filter(
              payment =>
                payment.cust_name === state.cust_name &&
                payment.project_name === state.project_name
            )
          } else if (state.cust_name && !state.project_name) {
            // Filter quotation based on cust_name only
            filteredPayments = response.data.filter(
              payment => payment.cust_name === state.cust_name
            )
          } else if (!state.cust_name && state.project_name) {
            // Filter quotation based on project_name only
            filteredPayments = response.data.filter(
              payment => payment.project_name === state.project_name
            )
          }

          setQuotation(filteredPayments)
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    const totalAmount = quotation.reduce(
      (acc, row) => acc + parseFloat(row.total_amount || 0),
      0.0
    )
    setTotalamount(totalAmount)
  })

  //Print
  const printRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" /> */}
          <Row className="mb-3">
            <Col xl="12">
              <div>
                <h5 className="mt-2">Sales Quotations</h5>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="12">
              <Card>
                <CardTitle>
                  <CardBody>
                    <Row>
                      <Col xl={3}>
                        <div className="mb-3">
                          <label className="">Customer</label>
                          <InputGroup>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState}
                              name="cust_name"
                              value={state.cust_name}
                            >
                              <option value={""}>Select Customer...</option>

                              {customers.length > 0 ? (
                                customers
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option
                                      key={index}
                                      value={e.bussiness_name}
                                    >
                                      {`${e.bussiness_name} (${e.acc_no})`}
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-sm-auto">
                          <label className="" htmlFor="autoSizingSelect">
                            Project
                          </label>
                          <InputGroup>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState}
                              name="project_name"
                              value={state.project_name}
                            >
                              <option value={""}>Select project ...</option>
                              {projects.length > 0 ? (
                                projects
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.project_name}>
                                      {e.project_name} ({e.project_code})
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No project found
                                </option>
                              )}
                            </select>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <Button
                          className="btn btn-primary mt-4"
                          color="primary"
                          onClick={handleFilter}
                        >
                          Filter
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </CardTitle>
              </Card>
            </Col>
          </Row>
          {filterDiv && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      {/* <h4 className="float-end font-size-16">
                      Order # {orderNo}
                    </h4> */}
                      <div className="mb-4">
                        <img
                          src={logo}
                          alt="logo-dark"
                          className="logo-dark-element"
                          height="80"
                        />
                      </div>
                    </div>
                    {/* <hr /> */}
                    <Row className="mb-3">
                      <Col xl={12}>
                        <h3 className="text-center">Front Force</h3>
                        <h5 className="text-center">Sale Quotations</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <address>
                          {state.cust_name && (
                            <>
                              <strong>Filter Customer : </strong>{" "}
                              {state.cust_name}
                            </>
                          )}
                          <br />
                          {state.project_name && (
                            <>
                              <strong>Filter Project : </strong>{" "}
                              {state.project_name}
                            </>
                          )}
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12}>
                        <table>
                          <thead
                            style={{
                              border: "1px solid black",
                              borderCollapse: "-moz-initial",
                            }}
                          >
                            <tr className="black-head">
                              <th>V.ID</th>
                              <th>Date</th>
                              <th>AC/No</th>
                              <th>Customer</th>
                              <th>Order No</th>
                              <th>Doc No</th>
                              <th>Project</th>
                              <th>Status</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quotation.length > 0 ? (
                              quotation.map(item => (
                                <tr key={item.id}>
                                  <td>
                                    <Link
                                      to={`/income/view_sale_order/${item.order_id}`}
                                    >
                                      {item.order_id}
                                    </Link>
                                  </td>
                                  <td>{item.order_date}</td>
                                  <td>{item.acc_no}</td>
                                  <td>{item.cust_name}</td>
                                  <td>{item.order_id}</td>
                                  <td>{item.order_doc_no}</td>
                                  <td>{item.project_name}</td>
                                  <td>{item.order_status}</td>
                                  <td>{formatAmount(item.total_amount)}</td>
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan="9" className="border-0">
                                    <h6 className="text-center mt-2 mb-2">
                                      No Transactions Found{" "}
                                    </h6>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                          <tbody>
                            <tr
                              style={{
                                border: "1px solid black",
                              }}
                            ></tr>
                            <tr>
                              <td colSpan={8}>
                                <b>Total</b>
                              </td>
                              <td>
                                <b> {formatAmount(totalamount)}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-print-none">
                          <div className="float-end">
                            <Link
                              onClick={handlePrint}
                              className="btn btn-success  me-2"
                            >
                              <i className="fa fa-print" />
                            </Link>
                            {/* <Link to="#" className="btn btn-primary w-md ">
                        Send
                      </Link> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <div
            ref={printRef}
            className="paymentPrint"
            style={{ display: "none" }}
          >
            <Row>
              <Col xl={12}>
                <div className="invoice-title">
                  <div className="mb-4">
                    <img
                      src={logo}
                      alt="logo-dark"
                      className="logo-dark-element"
                      height="80"
                    />
                  </div>
                </div>
                <hr />
                <Row>
                  <Col xl={12}>
                    <h3 className="text-center">Front Force</h3>
                    <h5 className="text-center">Sale Quotations</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xl={12}>
                    <table className="table-bordered border-black mt-2 ">
                      <thead
                        style={{
                          border: "1px solid black",
                          borderCollapse: "-moz-initial",
                        }}
                      >
                        <tr className="black-head">
                          <th>Order No</th>
                          <th>Date</th>
                          <th>AC/No</th>
                          <th>Customer</th>
                          <th>Project</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quotation.length > 0 ? (
                          quotation.map(item => (
                            <tr key={item.id}>
                              <td>{item.order_id}</td>
                              <td>{item.order_date}</td>
                              <td>{item.acc_no}</td>
                              <td>{item.cust_name}</td>
                              <td>{item.project_name}</td>
                              <td>{formatAmount(item.total_amount)}</td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr>
                              <td colSpan="9" className="border-0">
                                <h6 className="text-center mt-2 mb-2">
                                  No Transactions Found{" "}
                                </h6>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                      <tbody>
                        <tr
                          style={{
                            border: "1px solid black",
                          }}
                        ></tr>
                        <tr>
                          <td colSpan={5}>
                            <b>Total</b>
                          </td>
                          <td>
                            <b> {formatAmount(totalamount)}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="d-print-none">
                      <div className="float-end">
                        <Link
                          onClick={handlePrint}
                          className="btn btn-success  me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        {/* <Link to="#" className="btn btn-primary w-md ">
                        Send
                      </Link> */}
                      </div>
                    </div>
                  </Col>
                </Row>
                <br />
              </Col>
            </Row>
            <div className="mt-5 mb-5">
              <Row>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Prepared By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Reviewed By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Approved By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Received By</b>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col xl="12">
                  <div style={{ backgroundColor: "blue", padding: "10px" }}>
                    <img
                      src={`${apiUrl}/uploads/images/company_stamp.jpg`}
                      width="15%"
                      height="15%"
                    />

                    <p style={{ color: "white", marginTop: "5px" }}>
                      xyz <br />
                      0000-000-000 <br />
                      test@gmail.com
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

SaleQuotationReport.propTypes = {
  match: PropTypes.any,
}

export default withRouter(SaleQuotationReport)
