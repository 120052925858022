import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { formatAmount } from "components/Functoins/functions"
import logo from "../../assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  InputGroup,
  Button,
} from "reactstrap"
import "../../assets/style/style.css"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { useReactToPrint } from "react-to-print"
import axios from "axios"

const SaleCustomerReport = props => {
  //meta title
  document.title = "Bank Report"

  const [customerdata, setCustomerdata] = useState([])
  const [filterDiv, setFilterdiv] = useState(false)
  const [projects, setProjects] = useState([])
  const [customers, setCustomers] = useState([])
  const [totalpayment, setTotalpayment] = useState("")
  const [totalreceipt, setTotalreceipt] = useState("")
  const [state, setState] = useState({
    cust_name: "",
    project_name: "",
  })

  const handleState = e => {
    const { name, value } = e.target
    setState(prData => ({
      ...prData,
      [name]: value,
    }))
  }

  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Customer
  const fetchCustomers = () => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_customers")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCustomers(response.data)
        } else {
          console.log("Error in fetch customers")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchCustomers()
    fetchProject()
  }, [])

  const handleFilter = () => {
    setShowQuotationReport(false)
    setShowInvoiceReport(false)
    setCheckInvoice(false)
    setCheckQuotation(false)
    if (state.cust_name.length === 0) {
      toastr.error("Set Customer")
    } else {
      const URL = `${apiUrl}/Invoices/invoice.php`
      const Pdata = new FormData()
      Pdata.append("cutomer_name", state.cust_name)
      Pdata.append("app_method", "fetch_customer_bank_activity")
      setFilterdiv(true)
      axios
        .post(URL, Pdata)
        .then(response => {
          if (Array.isArray(response.data)) {
            let filteredPayments = response.data

            if (state.cust_name && state.project_name) {
              // Filter customerdata based on both cust_name and project_name
              filteredPayments = response.data.filter(
                payment =>
                  payment.cust_name === state.cust_name &&
                  payment.project_name === state.project_name
              )
            } else if (state.cust_name && !state.project_name) {
              // Filter customerdata based on cust_name only
              filteredPayments = response.data.filter(
                payment => payment.cust_name === state.cust_name
              )
            } else if (!state.cust_name && state.project_name) {
              // Filter customerdata based on project_name only
              filteredPayments = response.data.filter(
                payment => payment.project_name === state.project_name
              )
            }

            setCustomerdata(filteredPayments)
          } else {
            console.log("Error in fetch Data")
            setCustomerdata([])
          }
        })
        .catch(error => console.log(error))
    }
  }

  //Quotation Report
  const [checkQuotation, setCheckQuotation] = useState(false)
  const [quotationReportData, setQuotationReportData] = useState([])
  const [TotalQAmount, setTotalQAmount] = useState([])
  const [showQuotationReport, setShowQuotationReport] = useState(false)

  const handleQuotationReport = () => {
    setCheckQuotation(prevState => !prevState)
    if (!checkQuotation) {
      quotationReport()
      setShowQuotationReport(true)
    } else {
      setShowQuotationReport(false)
      setQuotationReportData([])
    }
  }
  const quotationReport = () => {
    setQuotationReportData([])
    const URL = `${apiUrl}/sales/orders.php`
    const data = new FormData()
    data.append("app_method", "fetch_all_orders")

    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          let FetchData = response.data
          if (state.cust_name && state.project_name) {
            FetchData = response.data.filter(
              item =>
                item.cust_name === state.cust_name &&
                item.project_name === state.project_name
            )
          } else if (state.cust_name) {
            FetchData = response.data.filter(
              item => item.cust_name === state.cust_name
            )
          } else if (state.project_name) {
            FetchData = response.data.filter(
              item => item.project_name === state.project_name
            )
          }
          setQuotationReportData(FetchData)
        } else {
          console.log("error")
        }
      })
      .catch(error => console.log(error))
  }

  // Invoice Report
  const [checkInvoice, setCheckInvoice] = useState(false)
  const [InvoiceReportData, setInvoiceReportData] = useState([])
  const [TotalIAmount, setTotalIAmount] = useState([])
  const [showInvoiceReport, setShowInvoiceReport] = useState(false)

  const handleInvoiceReport = () => {
    setCheckInvoice(prevState => !prevState)
    if (!checkInvoice) {
      invoiceReport()
      setShowInvoiceReport(true)
    } else {
      setShowInvoiceReport(false)
      setInvoiceReportData([])
    }
  }

  const invoiceReport = () => {
    const URL = `${apiUrl}/Invoices/invoice.php`
    const inv = new FormData()
    inv.append("app_method", "fetch_all_invoices")
    axios
      .post(URL, inv)
      .then(response => {
        if (Array.isArray(response.data)) {
          let FetchData = response.data

          if (state.cust_name && state.project_name) {
            FetchData = response.data.filter(
              item =>
                item.cust_name === state.cust_name &&
                item.project_name === state.project_name
            )
          } else if (state.project_name) {
            FetchData = response.data.filter(
              item => item.project_name === state.project_name
            )
          } else if (state.cust_name) {
            FetchData = response.data.filter(
              item => item.cust_name === state.cust_name
            )
          }
          setInvoiceReportData(FetchData)
        } else {
          console.log("error")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    const totalPayment = customerdata.reduce(
      (acc, row) => acc + parseFloat(row.payments_ || 0),
      0.0
    )
    const totalReceipt = customerdata.reduce(
      (acc, row) => acc + parseFloat(row.total_pay_amount || 0),
      0.0
    )
    setTotalpayment(totalPayment)
    setTotalreceipt(totalReceipt)

    const totalQuotationAmount = quotationReportData.reduce(
      (acc, item) => acc + parseFloat(item.total_amount || 0),
      0.0
    )
    setTotalQAmount(totalQuotationAmount)

    const totalInvoicedAmount = InvoiceReportData.reduce(
      (acc, item) => acc + parseFloat(item.total_amount || 0),
      0.0
    )
    setTotalIAmount(totalInvoicedAmount)
  }, [customerdata, quotationReportData, InvoiceReportData])
  //Print
  const printRef1 = useRef(null)

  const handlePrint1 = useReactToPrint({
    content: () => printRef1.current,
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" /> */}
          <Row className="mb-3">
            <Col xl="12">
              <div>
                <h5 className="mt-2">Customer Report</h5>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="12">
              <Card>
                <CardTitle>
                  <CardBody>
                    <Row>
                      <Col xl={3}>
                        <div className="mb-3">
                          <label className="">Customer</label>
                          <InputGroup>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState}
                              name="cust_name"
                              value={state.cust_name}
                            >
                              <option value={""}>Select Customer...</option>

                              {customers.length > 0 ? (
                                customers
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option
                                      key={index}
                                      value={e.bussiness_name}
                                    >
                                      {`${e.bussiness_name} (${e.acc_no})`}
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-sm-auto">
                          <label className="" htmlFor="autoSizingSelect">
                            Project
                          </label>
                          <InputGroup>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState}
                              name="project_name"
                              value={state.project_name}
                            >
                              <option value={""}>Select project ...</option>
                              {projects.length > 0 ? (
                                projects
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.project_name}>
                                      {e.project_name} ({e.project_code})
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No project found
                                </option>
                              )}
                            </select>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <Button
                          className="btn btn-primary mt-4"
                          color="primary"
                          onClick={handleFilter}
                        >
                          Filter
                        </Button>
                      </Col>
                    </Row>
                    {filterDiv && (
                      <Row>
                        <Col xl={2}>
                          <div className="form-check form-switch form-switch-sm mb-3 mt-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customerquotationreport"
                              onClick={handleQuotationReport}
                              checked={checkQuotation}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customerquotationreport"
                            >
                              Quotation Report
                            </label>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="form-check form-switch form-switch-sm mb-3 mt-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customerinvoicereport"
                              onClick={handleInvoiceReport}
                              checked={checkInvoice}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customerinvoicereport"
                            >
                              Invoice Report
                            </label>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </CardTitle>
              </Card>
            </Col>
          </Row>
          {filterDiv && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      {/* <h4 className="float-end font-size-16">
                      Order # {orderNo}
                    </h4> */}
                      <div className="mb-4">
                        <img
                          src={logo}
                          alt="logo-dark"
                          className="logo-dark-element"
                          height="80"
                        />
                      </div>
                    </div>
                    {/* <hr /> */}
                    <Row className="mb-3">
                      <Col xl={12}>
                        <h3 className="text-center">Front Force</h3>
                        <h5 className="text-center">Customer Report's</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={12}>
                        <h5 className="text-center mt-2 mb-2">
                          <strong>Customer Bank Transaction History</strong>
                        </h5>
                        <table>
                          <thead
                            style={{
                              border: "1px solid black",
                              borderCollapse: "-moz-initial",
                            }}
                          >
                            <tr className="black-head">
                              <th>V.ID</th>
                              <th>Date</th>
                              <th>Bank</th>
                              <th>Customer</th>
                              <th>Project</th>
                              <th>Pay Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customerdata.length > 0 ? (
                              customerdata.map((item, index) => (
                                <tr key={item.id}>
                                  <td>
                                    <Link
                                      to={`/income/view_invoice/view_payment/${item.vo_no}`}
                                    >
                                      {item.vo_no}
                                    </Link>
                                  </td>
                                  <td>{item.created_date}</td>
                                  <td>
                                    {item.bank_} ({item.bank_code})
                                  </td>
                                  <td>{item.cust_name}</td>
                                  <td>{item.project_name}</td>
                                  <td>{formatAmount(item.total_pay_amount)}</td>
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan="9" className="border-0">
                                    <h6 className="text-center mt-2 mb-2">
                                      No Transactions Found{" "}
                                    </h6>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                          <tbody>
                            <tr
                              style={{
                                border: "1px solid black",
                              }}
                            ></tr>
                            <tr>
                              <td colSpan={5}>
                                <b>Total</b>
                              </td>
                              <td>
                                <b> {formatAmount(totalreceipt)}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {showQuotationReport && (
                          <>
                            <h5 className="text-center mb-3 mt-5">
                              <strong>Customer Quotation Report</strong>
                            </h5>
                            <table>
                              <thead
                                style={{
                                  border: "1px solid black",
                                  borderCollapse: "-moz-initial",
                                }}
                              >
                                <tr className="black-head">
                                  <th>V.ID</th>
                                  <th>Date</th>
                                  <th>Doc No</th>
                                  <th>Customer</th>
                                  <th>Project</th>
                                  <th>Total Amount </th>
                                </tr>
                              </thead>
                              <tbody>
                                {quotationReportData.length > 0 ? (
                                  quotationReportData.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>
                                        <Link
                                          to={`/income/view_sale_order/${item.order_id}`}
                                        >
                                          {item.order_id}
                                        </Link>
                                      </td>
                                      <td>{item.order_date}</td>
                                      <td>{item.order_doc_no}</td>
                                      <td>{item.cust_name}</td>
                                      <td>{item.project_name}</td>
                                      <td>{formatAmount(item.total_amount)}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan="9" className="border-0">
                                        <h6 className="text-center mt-2 mb-2">
                                          No Transactions Found{" "}
                                        </h6>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                              <tbody>
                                <tr
                                  style={{
                                    border: "1px solid black",
                                  }}
                                ></tr>
                                <tr>
                                  <td colSpan={5}>
                                    <b>Total</b>
                                  </td>
                                  <td>
                                    <b> {formatAmount(TotalQAmount)}</b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                        {showInvoiceReport && (
                          <>
                            <h5 className="text-center mb-3 mt-5">
                              <strong>Customer Invoice's Report</strong>
                            </h5>
                            <table>
                              <thead
                                style={{
                                  border: "1px solid black",
                                  borderCollapse: "-moz-initial",
                                }}
                              >
                                <tr className="black-head">
                                  <th>V.ID</th>
                                  <th>Date</th>
                                  <th>Doc No</th>
                                  <th>Customer</th>
                                  <th>Project</th>
                                  <th>Total Amount </th>
                                </tr>
                              </thead>
                              <tbody>
                                {InvoiceReportData.length > 0 ? (
                                  InvoiceReportData.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>
                                        <Link
                                          to={`/income/view_sale_invoice/${item.inv_no}`}
                                        >
                                          {item.inv_no}
                                        </Link>
                                      </td>
                                      <td>{item.order_date}</td>
                                      <td>{item.order_doc_no}</td>
                                      <td>{item.cust_name}</td>
                                      <td>{item.project_name}</td>
                                      <td>{formatAmount(item.total_amount)}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan="9" className="border-0">
                                        <h6 className="text-center mt-2 mb-2">
                                          No Transactions Found{" "}
                                        </h6>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                              <tbody>
                                <tr
                                  style={{
                                    border: "1px solid black",
                                  }}
                                ></tr>
                                <tr>
                                  <td colSpan={5}>
                                    <b>Total</b>
                                  </td>
                                  <td>
                                    <b> {formatAmount(TotalIAmount)}</b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}

                        <div className="d-print-none mt-2 mb-2">
                          <div className="float-end">
                            <Link
                              onClick={handlePrint1}
                              className="btn btn-success  me-2"
                            >
                              <i className="fa fa-print" />
                            </Link>
                            {/* <Link to="#" className="btn btn-primary w-md ">
                            Send
                             </Link> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <div
            ref={printRef1}
            className="paymentPrint"
            style={{ display: "none" }}
          >
            <Row>
              <Col xl={12}>
                <div className="invoice-title">
                  <div className="mb-4">
                    <img
                      src={logo}
                      alt="logo-dark"
                      className="logo-dark-element"
                      height="80"
                    />
                  </div>
                </div>
                <hr />
                <Row>
                  <Col xl={12}>
                    <h3 className="text-center">Front Force</h3>
                    <h5 className="text-center">
                      Customer Bank Transaction History
                    </h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xl={12}>
                    <table className="table-bordered border-black mt-2 ">
                      <thead
                        style={{
                          border: "1px solid black",
                          borderCollapse: "-moz-initial",
                        }}
                      >
                        <tr className="black-head">
                          <th>V.ID</th>
                          <th>Date</th>
                          <th>Bank</th>
                          <th>Customer</th>
                          <th>Project</th>
                          <th>Pay Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerdata.length > 0 ? (
                          customerdata.map((item, index) => (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.created_date}</td>
                              <td>
                                {item.bank_} ({item.bank_code})
                              </td>
                              <td>{item.cust_name}</td>
                              <td>{item.project_name}</td>
                              <td>{formatAmount(item.total_pay_amount)}</td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr>
                              <td colSpan="9" className="border-0">
                                <h6 className="text-center mt-2 mb-2">
                                  No Transactions Found{" "}
                                </h6>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                      <tbody>
                        <tr
                          style={{
                            border: "1px solid black",
                          }}
                        ></tr>
                        <tr>
                          <td colSpan={5}>
                            <b>Total</b>
                          </td>

                          <td>
                            <b> {formatAmount(totalreceipt)}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <br />
              </Col>
            </Row>
            {showQuotationReport && (
              <Row>
                <Col xl={12}>
                  <Row>
                    <Col xl={12}>
                      <h5 className="text-center">Customer Quotation Report</h5>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xl={12}>
                      <table className="table-bordered border-black mt-2 ">
                        <thead
                          style={{
                            border: "1px solid black",
                            borderCollapse: "-moz-initial",
                          }}
                        >
                          <tr className="black-head">
                            <th>V.ID</th>
                            <th>Date</th>
                            <th>Doc No</th>
                            <th>Customer</th>
                            <th>Project</th>
                            <th>Total Amount </th>
                          </tr>
                        </thead>
                        <tbody>
                          {quotationReportData.length > 0 ? (
                            quotationReportData.map((item, index) => (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.order_date}</td>
                                <td>{item.order_doc_no}</td>
                                <td>{item.cust_name}</td>
                                <td>{item.project_name}</td>
                                <td>{formatAmount(item.total_amount)}</td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr>
                                <td colSpan="9" className="border-0">
                                  <h6 className="text-center mt-2 mb-2">
                                    No Transactions Found{" "}
                                  </h6>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                        <tbody>
                          <tr
                            style={{
                              border: "1px solid black",
                            }}
                          ></tr>
                          <tr>
                            <td colSpan={5}>
                              <b>Total</b>
                            </td>

                            <td>
                              <b> {formatAmount(TotalQAmount)}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                </Col>
              </Row>
            )}
            {showInvoiceReport && (
              <Row>
                <Col xl={12}>
                  <Row>
                    <Col xl={12}>
                      <h5 className="text-center">Customer Invoice's Report</h5>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xl={12}>
                      <table className="table-bordered border-black mt-2 ">
                        <thead
                          style={{
                            border: "1px solid black",
                            borderCollapse: "-moz-initial",
                          }}
                        >
                          <tr className="black-head">
                            <th>V.ID</th>
                            <th>Date</th>
                            <th>Doc No</th>
                            <th>Customer</th>
                            <th>Project</th>
                            <th>Total Amount </th>
                          </tr>
                        </thead>
                        <tbody>
                          {InvoiceReportData.length > 0 ? (
                            InvoiceReportData.map((item, index) => (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.order_date}</td>
                                <td>{item.order_doc_no}</td>
                                <td>{item.cust_name}</td>
                                <td>{item.project_name}</td>
                                <td>{formatAmount(item.total_amount)}</td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr>
                                <td colSpan="9" className="border-0">
                                  <h6 className="text-center mt-2 mb-2">
                                    No Transactions Found{" "}
                                  </h6>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                        <tbody>
                          <tr
                            style={{
                              border: "1px solid black",
                            }}
                          ></tr>
                          <tr>
                            <td colSpan={5}>
                              <b>Total</b>
                            </td>

                            <td>
                              <b> {formatAmount(TotalIAmount)}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                </Col>
              </Row>
            )}
            <div className="mt-5 mb-5">
              <Row>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Prepared By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Reviewed By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Approved By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Received By</b>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col xl="12">
                  <div style={{ backgroundColor: "blue", padding: "10px" }}>
                    <img
                      src={`${apiUrl}/uploads/images/company_stamp.jpg`}
                      width="15%"
                      height="15%"
                    />

                    <p style={{ color: "white", marginTop: "5px" }}>
                      xyz <br />
                      0000-000-000 <br />
                      test@gmail.com
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

SaleCustomerReport.propTypes = {
  match: PropTypes.any,
}

export default withRouter(SaleCustomerReport)
