import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
import "../../../assets/style/style.css"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  colspan,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  ToastHeader,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import logo from "../../../assets/images/Pham logo black text.png"
// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"
import { useReactToPrint } from "react-to-print"
import SocialModal from "components/SocialControl/Social"

const ViewPurchaseBill = props => {
  //meta title
  document.title = "View Purchase Bill"
  const { id } = useParams()
  const bill_id = id

  const iconRef = useRef(null)
  const iconRef2 = useRef(null)

  const navigate = useNavigate()

  const [totalQuantity, setTotalQuantity] = useState(0)
  const [subTotalAmount, setSubTotalAmount] = useState(0)
  const [FinalTotalAmount, setFinalTotalAmount] = useState(0)
  const [Tax_amount, setTax_amount] = useState(0)

  const [TotalExpenseAmount, setTotalExpenseAmount] = useState(0)
  const [TotalPrdAmount, setTotalPrdAmount] = useState(0)

  const [suppliers, setSuppliers] = useState([])
  const [expenses, setExpenses] = useState([])
  const [products, setProducrts] = useState([])
  const [totalamount, setTotalamount] = useState("")
  const [projects, setProjects] = useState([])

  const [supCode, setSupCode] = useState("")
  const [prjCode, setprjCode] = useState("")

  const [payments, setPayments] = useState([])

  const [EditMode, setEditMode] = useState(false)

  const [bank, setBank] = useState([])
  const [pay_mode, setPaymode] = useState([])

  const [loading, setloading] = useState(false)

  //accordion
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(true)
  const [col3, setcol3] = useState(true)
  const [col4, setcol4] = useState(true)

  const [count, setCount] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
  }
  const t_col2 = () => {
    setcol2(!col2)
  }
  const t_col3 = () => {
    setcol3(!col3)
  }
  const t_col4 = () => {
    setcol4(!col4)
  }

  //Eamil
  const [socialModal, setSocialModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")

  //Modal Mail Data
  const [ModalMailData, setModalMailData] = useState({
    from_mail: "",
    to_mail: "",
    mail_subj: "",
    email_message:
      "<p>Please find attached confirmation for your Order.<br>Thank you for your business.<br>Best Regards.<br><strong>Front Force</strong> ",
  })
  const handleModalMailData = e => {
    const { name, value } = e.target
    setModalMailData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const [EmailMode, setEmailMode] = useState(false)
  const handleEmail = () => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const post = new FormData()
    post.append("pur_id", bill_id)
    post.append("app_method", "fetch_bill_det_byId")
    axios
      .post(URL, post)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSocialModal(!socialModal)
          setPdfUrl(response.data[0].pdf_url)
          setEmailMode(true)
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  const SendEmail = () => {
    if (ModalMailData.to_mail === "") {
      toastr.error("Sent Email is Required")
    } else {
      const data = new FormData()
      data.append("from_mail", ModalMailData.from_mail)
      data.append("to_mail", ModalMailData.to_mail)
      data.append("mail_subj", ModalMailData.mail_subj)
      data.append("email_message", ModalMailData.email_message)
      data.append("pdf_url", pdfUrl)
      data.append("app_method", "send_email")
      const URL = `${apiUrl}/socialaccounts/emailhandle.php`
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.message === "success") {
            toastr.success("Email Sent Successfully")
            setSocialModal(!socialModal)
            navigate("/expenses/control/bills")
          } else {
            // Handle case where response.data is not an array
            console.error("Response data is not an array.")
          }
        })
        .catch(error => console.log(error))
    }
  }
  //Toggle
  const toggleModal = () => {
    setSocialModal(!socialModal)
    navigate("/expenses/control/bills")
  }

  //Clear localstorage

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("delete_FEids")
      localStorage.removeItem("delete_FPids")
    }

    const handlePopState = () => {
      clearLocalStorage()
    }

    navigate(clearLocalStorage)

    window.addEventListener("popstate", handlePopState)

    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [navigate])

  //Supplier Datail Accordion
  const [formData, setFormData] = useState({
    payment_date_: new Date().toISOString().substr(0, 10),
    project_name: "",
    payment_mode: "",
    payment_ref_no: "PHAM-",
    payment_amount: "",
    supplier_name: "",
    address_: "",
    date_: new Date().toISOString().substr(0, 10),
    term_days: "",
    due_date: new Date().toISOString().substr(0, 10),
    bill_no: "PHAM-",
    cred_limt: "",
    balance_: "",
    total_amount: "",
    final_notes: "",
    discount_Amount: "",
    tax_percentage: "",
  })
  const handleChange = e => {
    const { name, value } = e.target
    if (value === "add_mode") {
      addMode()
    } else if (value === "add_bank") {
      addBankAcc()
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }

    if (name === "term_days") {
      const termDays = parseInt(value)
      const today = new Date(formData.date_)
      const dueDate = new Date(
        today.getTime() + parseInt(value) * 24 * 60 * 60 * 1000
      )
      if (!isNaN(termDays) && termDays > 0) {
        setFormData({
          ...formData,
          due_date: dueDate.toISOString().substr(0, 10),
          term_days: value,
        })
      } else {
        setFormData({
          ...formData,
          due_date: new Date().toISOString().substr(0, 10),
          term_days: value,
        })
      }
    }

    if (name === "discount_Amount") {
      const Amount = parseFloat(value)
      if (Amount >= subTotalAmount) {
        toastr.error("Discount Amount is less than Sub Total")
      } else {
        setFormData(prevData => ({
          ...prevData,
          discount_Amount: value,
        }))
      }
    }
  }

  //Fetch Purchase Detail By ID
  const fetchPurchaseData = async id => {
    try {
      setloading(true) // Set loading state to true while fetching data
      const URL = `${apiUrl}/purchases/addPurchases.php`
      const post = new FormData()
      post.append("pur_id", id)
      post.append("app_method", "fetch_bill_det_byId")
      const response = await axios.post(URL, post)

      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          const purchaseData = response.data[0]
          setFormData(prevData => ({
            ...prevData,
            supplier_name: purchaseData.supplier_name || "",
            project_name: purchaseData.project_name || "",
            address_: purchaseData.supplier_add || "",
            bill_no: purchaseData.purchase_bill_no || "",
            date_: purchaseData.purchase_date || "",
            term_days: purchaseData.delivery_day || "",
            due_date: purchaseData.due_date || "",
            final_notes: purchaseData.final_notes || "",
            total_amount: purchaseData.total_amount || "",
            balance_: purchaseData.balance_ || "",
            tax_percentage: purchaseData.tax_percentage || "",
            discount_Amount: purchaseData.discount || "",
          }))

          setTax_amount(parseFloat(purchaseData.tax_amount) || 0)
          setSubTotalAmount(parseFloat(purchaseData.sub_total) || 0)
          setFinalTotalAmount(parseFloat(purchaseData.total_amount) || 0)
        } else {
          setFormData(prevData => ({
            ...prevData,
          }))
        }
      } else {
        console.log("Error in fetchPurchaseData: Response is not an array")
      }
    } catch (error) {
      console.error("Error fetching purchase data:", error)
    } finally {
      setloading(false) // Finally block ensures that loading state is set to false regardless of success or failure
    }
  }

  //Expense Detail

  //Fetch Expense Detail
  const fetchExpenseDetail = id => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const post = new FormData()
    post.append("exp_id", id)
    post.append("app_method", "fetch_expens_det_byId")
    axios
      .post(URL, post)
      .then(response => {
        if (Array.isArray(response.data)) {
          if (response.data.length > 0) {
            const fetchedData = response.data.map(item => ({
              FEid: item.id || "",
              Fexpense_: item.expense_ || "",
              Fexpense_desc: item.expense_desc || "",
              Fexpense_amount: item.expense_amount || "",
            }))
            setFEdata(fetchedData)
          } else {
            setFEdata([])
          }
        } else {
          console.log("error")
          setFEdata([])
        }
      })
      .catch(error => console.log(error))
  }
  const fetchinitialExpense = [
    {
      FEid: 1,
      Fexpense_: "",
      Fexpense_desc: "",
      Fexpense_amount: "",
    },
  ]
  const [FEdata, setFEdata] = useState(fetchinitialExpense)
  const handleEditFE = (id, field, value) => {
    setFEdata(prevData =>
      prevData.map(item =>
        item.FEid === id
          ? {
              ...item,
              [field]: value,
            }
          : item
      )
    )
  }
  const deleteFetchRow = id => {
    const updatedData = FEdata.filter(item => item.FEid !== id)
    setFEdata(updatedData)
    let existingIds =
      JSON.parse(localStorage.getItem("delete_FEids")) == null
        ? []
        : JSON.parse(localStorage.getItem("delete_FEids"))

    // // Add the current ID to the array
    existingIds.push(id)
    // // Store the updated array back to local storage
    localStorage.setItem("delete_FEids", JSON.stringify(existingIds))
  }

  //New Expense Detail
  const initialExpense = []
  const [Edata, setEdata] = useState(initialExpense)
  const handleEditE = (id, field, value) => {
    if (value === "add_expense") {
      AddExpense()
    } else {
      setEdata(prevData =>
        prevData.map(item =>
          item.id === id
            ? {
                ...item,
                [field]: value,
              }
            : item
        )
      )
    }
  }
  const handleAddERow = () => {
    const newId = Edata.length + 1
    const newRow = {
      id: newId,
      expense_: "",
      expense_desc: "",
      expense_amount: "",
    }
    setEdata(prevData => [...prevData, newRow])
  }
  const deleteERow = id => {
    const updatedData2 = Edata.filter(item => item.id !== id)
    setEdata(updatedData2)
  }

  //Fetch Product Detail
  const fetchBillProductDetail = id => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const post = new FormData()
    post.append("prd_id", id)
    post.append("app_method", "fetch_product_det_byId")
    axios
      .post(URL, post)
      .then(response => {
        if (Array.isArray(response.data)) {
          if (response.data.length > 0) {
            const fetchedData2 = response.data.map(item => ({
              FPid: item.id || "",
              Fproduct_: item.product_name || "",
              Fprd_desc: item.prd_des || "",
              Fprd_unit: item.prd_unit || "",
              Fprd_code: item.prd_code || "",
              Fprd_qty: item.prd_qty || "",
              Fprd_rate: item.prd_rate || "",
              Fprd_amount: item.prd_amount || "0",
            }))
            setFPData(fetchedData2)
          } else {
            setFPData([])
          }
        } else {
          setFPData([])
          console.log("error")
        }
      })
      .catch(error => console.log(error))
  }

  //Fetch Product Detail
  const FPinitialData = [
    {
      FPid: 1,
      Fproduct_: "",
      Fprd_desc: "",
      Fprd_unit: "",
      Fprd_code: "",
      Fprd_qty: "",
      Fprd_rate: "",
      Fprd_amount: "0.00",
      Fdiscount_per: "",
      Fdiscount_: "",
      Ftax_rate: "",
      Fsale_tex_: "",
      Fnet_rate: "",
    },
  ]
  const [FPdata, setFPData] = useState(FPinitialData)

  const handleFPEdit = (id, field, value) => {
    setFPData(prevData =>
      prevData.map(item => {
        if (item.FPid === id) {
          return {
            ...item,
            [field]: value,
            Fprd_amount:
              field === "Fprd_qty" || field === "Fprd_rate"
                ? parseFloat(value !== "" ? value : "0") *
                  parseFloat(item.Fprd_rate !== "" ? item.Fprd_rate : "0")
                : item.Fprd_amount,
          }
        } else {
          return item
        }
      })
    )
    if (field === "Fproduct_") {
      fetchFPProductDetail(value, id)
    }
  }
  const handleFPEdit3 = (id, field, value) => {
    setFPData(prevData =>
      prevData.map(item => {
        if (item.FPid === id) {
          return {
            ...item,
            [field]: value,
            Fprd_amount:
              field === "Fprd_qty" || field === "Fprd_rate"
                ? parseFloat(item.Fprd_qty !== "" ? item.Fprd_qty : "0") *
                  parseFloat(value !== "" ? value : "0")
                : item.Fprd_amount,
          }
        } else {
          return item
        }
      })
    )
  }
  const deleteFPRow = id => {
    const updatedData = FPdata.filter(item => item.FPid !== id)
    setFPData(updatedData)
    let existingIds =
      JSON.parse(localStorage.getItem("delete_FPids")) == null
        ? []
        : JSON.parse(localStorage.getItem("delete_FPids"))

    // // Add the current ID to the array
    existingIds.push(id)
    // // Store the updated array back to local storage
    localStorage.setItem("delete_FPids", JSON.stringify(existingIds))
  }

  const fetchFPProductDetail = (prd_name, id) => {
    const URL = `${apiUrl}/products/addproduct.php`
    const Product = new FormData()

    Product.append("app_method", "get_product_detail")
    axios
      .post(URL, Product)
      .then(response => {
        if (Array.isArray(response.data)) {
          const SelectedProduct = response.data.find(
            response => response.prd_name === prd_name
          )
          if (SelectedProduct) {
            setFPData(prevData =>
              prevData.map(item =>
                item.FPid === id
                  ? {
                      ...item,
                      Fprd_desc: SelectedProduct.prd_sale_info || "",
                      Fprd_unit: SelectedProduct.prd_unit || "",
                      Fprd_rate: SelectedProduct.prd_sale_price || "",
                      Fprd_code: SelectedProduct.prd_code || "",
                    }
                  : item
              )
            )
          } else {
            console.log("Error in fetch Detail")
          }
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }

  //New Product Detail
  const initialData = []
  const [data, setData] = useState(initialData)

  const handleEdit = (id, field, value) => {
    setData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? parseFloat(value !== "" ? value : "0") *
                  parseFloat(item.prd_rate !== "" ? item.prd_rate : "0")
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
    if (field === "product_") {
      fetchProductDetail(value, id)
    }
  }
  const handleEdit3 = (id, field, value) => {
    setData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? parseFloat(item.prd_qty !== "" ? item.prd_qty : "0") *
                  parseFloat(value !== "" ? value : "0")
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
  }
  const handleAddRow = () => {
    const newId = data.length + 1
    const newRow = {
      id: newId,
      product_: "",
      prd_desc: "",
      prd_unit: "",
      prd_code: "",
      prd_qty: "",
      prd_rate: "",
      prd_amount: "0.00",
      discount_per: "",
      discount_: "",
      tax_rate: "",
      sale_tex_: "",
      net_rate: "",
    }
    setData(prevData => [...prevData, newRow])
  }
  const deleteRow = id => {
    const updatedData = data.filter(item => item.id !== id)
    setData(updatedData)
  }
  const fetchProductDetail = (prd_name, id) => {
    const URL = `${apiUrl}/products/addproduct.php`
    const Product = new FormData()

    Product.append("app_method", "get_product_detail")
    axios
      .post(URL, Product)
      .then(response => {
        if (Array.isArray(response.data)) {
          const SelectedProduct = response.data.find(
            response => response.prd_name === prd_name
          )
          if (SelectedProduct) {
            setData(prevData =>
              prevData.map(item =>
                item.id === id
                  ? {
                      ...item,
                      prd_desc: SelectedProduct.prd_sale_info || "",
                      prd_unit: SelectedProduct.prd_unit || "",
                      prd_rate: SelectedProduct.prd_sale_price || "",
                      prd_code: SelectedProduct.prd_code || "",
                    }
                  : item
              )
            )
          } else {
            console.log("Error in fetch Detail")
          }
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchPurchaseData(bill_id)
    fetchExpenseDetail(bill_id)
    fetchBillProductDetail(bill_id)
  }, [bill_id])

  //Fetch Suppliers
  const fetchSuppliers = () => {
    const URL = `${apiUrl}/purchases/suppliers.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_supplier")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSuppliers(response.data)
          const code = response.data.find(
            item => item.bussiness_name === formData.supplier_name
          )
          if (code) {
            setSupCode(code.acc_no)
          }
        } else {
          console.log("Error in fetch suppliers")
        }
      })
      .catch(error => console.log(error))
  }
  const fetchSupplierDetail = SupplierName => {
    const URL = `${apiUrl}/purchases/suppliers.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_supplier")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSuppliers(response.data)
          const selectedSupplier = response.data.find(
            supplier => supplier.bussiness_name === SupplierName
          )
          if (selectedSupplier) {
            setFormData(prevData => ({
              ...prevData,
              address_: selectedSupplier.address_ || "",
            }))
          } else {
            console.log("Error: Customer not found")
          }
        } else {
          console.log("Error in fetch suppliers")
        }
      })
      .catch(error => console.error("Error fetching suppliers:", error))
  }

  //fetchExpenseDetail
  const fetchExpense = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const data = new FormData()
    data.append("app_method", "fetch_expense_det")

    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setExpenses(response.data)
        } else {
          console.log("error in fethc expense detail")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    const subTotalAmount = data.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0.0
    )
    setTotalamount(subTotalAmount)
    fetchSuppliers()
    fetchProducts()
    fetchExpense()
  }, [formData.supplier_name])

  useEffect(() => {
    if (formData.supplier_name) {
      fetchSupplierDetail(formData.supplier_name)
    }
  }, [formData.supplier_name])

  useEffect(() => {
    let sumQuantity = 0
    let sumAmount = 0
    let sumprdRate = 0
    let sumExpenRate = 0

    let Discount = 0
    let FTotalAmount = 0
    let TaxPercentage = 0
    let TaxAmount = 0

    FEdata.forEach(item => {
      const amount = parseFloat(item.Fexpense_amount) || 0
      sumAmount += amount
      sumExpenRate += amount
    })

    Edata.forEach(item => {
      const amount2 = parseFloat(item.expense_amount) || 0
      sumAmount += amount2
      sumExpenRate += amount2
    })

    data.forEach(item => {
      const quantity = parseFloat(item.prd_qty) || 0
      const amount = parseFloat(item.prd_amount) || 0

      sumQuantity += quantity
      sumAmount += amount
      sumprdRate += amount
    })

    FPdata.forEach(item => {
      const quantity = parseFloat(item.Fprd_qty) || 0
      const amount = parseFloat(item.Fprd_amount) || 0

      sumQuantity += quantity
      sumAmount += amount
      sumprdRate += amount
    })

    // Initialize final total amount with the sum amount
    FTotalAmount = sumAmount

    // Apply discount if provided
    if (formData.discount_Amount !== null && formData.discount_Amount !== "") {
      const discountValue = parseFloat(formData.discount_Amount)
      Discount = discountValue
      FTotalAmount -= Discount
    }

    // Apply tax if provided
    if (formData.tax_percentage !== null && formData.tax_percentage !== "") {
      TaxPercentage = parseFloat(formData.tax_percentage) || 0
      TaxAmount = FTotalAmount * (TaxPercentage / 100)
      FTotalAmount += TaxAmount
    }

    setTotalQuantity(sumQuantity)
    setSubTotalAmount(sumAmount)

    setFinalTotalAmount(FTotalAmount)
    setTax_amount(TaxAmount)

    setTotalPrdAmount(sumprdRate)
    setTotalExpenseAmount(sumExpenRate)
  }, [
    data,
    FEdata,
    Edata,
    FPdata,
    formData.discount_Amount,
    formData.tax_percentage,
  ])

  //Submit Data
  const paid_amount = formData.total_amount - formData.balance_
  const SubmitPurchaseBillData = (e, isPrint) => {
    if (subTotalAmount < paid_amount) {
      toastr.error(
        `Total Amount is Equal or greater then paid amount <b>${paid_amount}</b>`
      )
    } else if (formData.supplier_name === "") {
      toastr.error("Set Supplier")
    } else if (formData.project_name === "") {
      toastr.error("Set  Project")
    } else if (formData.date_ === "") {
      toastr.error("Set Date")
    } else if (!FEdata.every(row => row.Fexpense_.length > 0)) {
      toastr.error("Set Expense")
    } else if (!FEdata.every(row => row.Fexpense_amount.length > 0)) {
      toastr.error("Set Expense Amount")
    } else if (!Edata.every(row => row.expense_.length > 0)) {
      toastr.error("Set Expense")
    } else if (!Edata.every(row => row.expense_amount.length > 0)) {
      toastr.error("Set Expense Amount")
    } else if (!data.every(row => row.product_.length > 0)) {
      toastr.error("Set Product")
    } else if (!data.every(row => row.prd_qty.length > 0)) {
      toastr.error("Set Product Quantity")
    } else if (!data.every(row => row.prd_rate.length > 0)) {
      toastr.error("Set Product Rate")
    } else if (!FPdata.every(row => row.Fproduct_.length > 0)) {
      toastr.error("Set Product")
    } else if (!FPdata.every(row => row.Fprd_qty.length > 0)) {
      toastr.error("Set Product Quantity")
    } else if (!FPdata.every(row => row.Fprd_rate.length > 0)) {
      toastr.error("Set Product Rate")
    } else if (formData.discount_Amount >= subTotalAmount) {
      toastr.error("Discount Amount is less than Sub Total")
    } else {
      e.preventDefault()
      const URl = `${apiUrl}/purchases/addPurchases.php`
      const formdata = new FormData()
      formdata.append("Update_id", bill_id)

      //Fetch expense detail
      FEdata.forEach(row => {
        formdata.append("FEid[]", row.FEid)
        formdata.append("Fexpense_name[]", row.Fexpense_)
        formdata.append("Fexpense_desc[]", row.Fexpense_desc)
        formdata.append("Fexpense_amount[]", row.Fexpense_amount)
      })
      //New expense detail
      Edata.forEach(row2 => {
        formdata.append("NEid[]", row2.id)
        formdata.append("expense_name[]", row2.expense_)
        formdata.append("expense_desc[]", row2.expense_desc)
        formdata.append("expense_amount[]", row2.expense_amount)
      })

      //Fetch prodcut detail
      FPdata.forEach(row => {
        formdata.append("FPid[]", row.FPid)
        formdata.append("Fproduct_name[]", row.Fproduct_)
        formdata.append("Fproduct_desc[]", row.Fprd_desc)
        formdata.append("Fproduct_unit[]", row.Fprd_unit)
        formdata.append("Fproduct_code[]", row.Fprd_code)
        formdata.append("Fproduct_qty[]", row.Fprd_qty)
        formdata.append("Fproduct_rate[]", row.Fprd_rate)
        formdata.append("Fproduct_amount[]", row.Fprd_amount)
      })
      //New prodcut detail
      data.forEach(row2 => {
        formdata.append("NPid[]", row2.id)
        formdata.append("product_name[]", row2.product_)
        formdata.append("product_desc[]", row2.prd_desc)
        formdata.append("product_unit[]", row2.prd_unit)
        formdata.append("product_code[]", row2.prd_code)
        formdata.append("product_qty[]", row2.prd_qty)
        formdata.append("product_rate[]", row2.prd_rate)
        formdata.append("product_amount[]", row2.prd_amount)
      })

      //payment detail
      // formdata.append("payment_date", formData.payment_date_)
      // formdata.append("payBank", formData.bank_name)
      // formdata.append("payMode", formData.payment_mode)
      // formdata.append("payment_ref_no", formData.payment_ref_no)
      // formdata.append("payment_amount", formData.payment_amount)

      //supplier detail
      formdata.append("supplier_name", formData.supplier_name)
      formdata.append("project_name", formData.project_name)
      formdata.append("supplier_address", formData.address_)
      formdata.append("purchase_date", formData.date_)
      formdata.append(
        "term_days",
        formData.term_days !== "" ? formData.term_days : "0"
      )
      formdata.append(
        "discount_amount",
        formData.discount_Amount !== "" ? formData.discount_Amount : "0"
      )
      formdata.append(
        "tax_percentage",
        formData.tax_percentage !== "" ? formData.tax_percentage : "0"
      )
      formdata.append("purchase_due_date", formData.due_date)
      formdata.append("purchase_bill_no", formData.bill_no)
      formdata.append("final_notes", formData.final_notes)

      formdata.append("total_quantity", totalQuantity)
      formdata.append("tax_amount", Tax_amount !== "" ? Tax_amount : "0")
      formdata.append("sub_total", subTotalAmount)
      formdata.append("total_amount", FinalTotalAmount)

      formdata.append("total_expense_amount", TotalExpenseAmount)
      formdata.append("total_prd_qty", totalQuantity)
      formdata.append("total_prd_amount", TotalPrdAmount)

      let deletedExpenseIds =
        JSON.parse(localStorage.getItem("delete_FEids")) || []
      deletedExpenseIds.forEach(id => {
        formdata.append("del_expense_id[]", id)
      })

      let deletedProductIds =
        JSON.parse(localStorage.getItem("delete_FPids")) || []
      deletedProductIds.forEach(id => {
        formdata.append("del_product_id[]", id)
      })
      formdata.append("paid_amount", paid_amount)
      formdata.append("app_method", "update_purchase_bill")

      axios
        .post(URl, formdata)
        .then(response => {
          if (response.data === "success") {
            if (isPrint) {
              // Open print modal with returned ID
              handleEmail(bill_id)
              localStorage.removeItem("delete_FEids")
              localStorage.removeItem("delete_FPids") // Assuming response contains ID
            } else {
              toastr.success("Add Successfully")
              navigate("/expenses/control/bills")
              localStorage.removeItem("delete_FEids")
              localStorage.removeItem("delete_FPids")
            }
          } else {
            toastr.error("Error in Sumbittion")
            localStorage.removeItem("delete_FEids")
            localStorage.removeItem("delete_FPids")
          }
        })
        .catch(error => console.log(error))
    }
  }

  //Products
  const fetchProducts = () => {
    const URL = `${apiUrl}/products/addproduct.php`
    const Product = new FormData()

    Product.append("app_method", "get_product_detail")
    axios
      .post(URL, Product)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProducrts(response.data)
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }
  //Bank
  const fetchBank = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    axios
      .post(URL, Data)
      .then(response => {
        if (response.data) {
          setBank(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Pay Mode
  const fetchPayMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (response.data) {
          setPaymode(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Projects
  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
          const code = response.data.find(
            item => item.project_name === formData.project_name
          )
          if (code) {
            setprjCode(code.project_code)
          }
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    fetchBank()
    fetchPayMode()
    fetchProject()
  }, [formData.project_name])

  //Handle Supplier
  const handleChangeSupplier = selectedOption => {
    if (selectedOption && selectedOption.value) {
      setFormData(prevData => ({
        ...prevData,
        supplier_name: selectedOption.value,
      }))
    }
  }

  //Close
  const close = () => {
    localStorage.removeItem("delete_FEids")
    localStorage.removeItem("delete_FPids")
    navigate("/expenses/control/bills")
  }

  //Textarea lenght
  const [textareaHeights, setTextareaHeights] = useState({})
  const [textareaHeights2, setTextareaHeights2] = useState({})
  const [textareaHeights3, setTextareaHeights3] = useState({})

  const handleTextareaChange = (id, value) => {
    const textarea = document.getElementById(`textarea-${id}`)
    if (textarea) {
      textarea.style.height = "auto"
      textarea.style.height = textarea.scrollHeight + "px"
      setTextareaHeights(prevHeights => ({
        ...prevHeights,
        [id]: textarea.scrollHeight,
      }))
    }
  }
  const handleTextareaChange2 = (id, value) => {
    const textarea2 = document.getElementById(`textarea2-${id}`)
    if (textarea2) {
      textarea2.style.height = "auto"
      textarea2.style.height = textarea2.scrollHeight + "px"
      setTextareaHeights2(prevHeights => ({
        ...prevHeights,
        [id]: textarea2.scrollHeight,
      }))
    }
  }
  const handleTextareaChange3 = (id, value) => {
    const textarea3 = document.getElementById(`textarea3-${id}`)
    if (textarea3) {
      textarea3.style.height = "auto"
      textarea3.style.height = textarea3.scrollHeight + "px"
      setTextareaHeights3(prevHeights => ({
        ...prevHeights,
        [id]: textarea3.scrollHeight,
      }))
    }
  }

  useEffect(() => {
    FPdata.map(item => {
      handleTextareaChange2(item.FPid, item.Fprd_desc)
    })
    data.map(item => {
      handleTextareaChange3(item.id, item.prd_desc)
    })
  }, [FPdata, data])

  //EditBill
  const EditBill = () => {
    setEditMode(!EditMode)
  }

  //Trans History
  const fetchBankActivity = id => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const data = new FormData()
    data.append("id", id)
    data.append("app_method", "fecth_purchase_payment_history_byID")

    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          // Assuming invoice_id is defined elsewhere
          const invoiceData = response.data
          if (invoiceData.length > 0) {
            setPayments(invoiceData)
            setCount(true)
          } else {
            console.log("Error: Transaction is not found")
          }
        } else {
          console.log("Error: Response data is not an array")
        }
      })
      .catch(error => console.log("Error:", error))
  }

  useEffect(() => {
    fetchBankActivity(bill_id)
  }, [bill_id])

  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setDiscountMode(false)
    }
    if (iconRef2.current && !iconRef2.current.contains(event.target)) {
      setTaxMode(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  // Function to handle "Save & Close" button click
  const handleSaveAndClose = e => {
    SubmitPurchaseBillData(e, false) // Call SubmitSaleData function with isPrint as false
  }
  // Function to handle "Save & Print" button click
  const handleSaveAndPrint = e => {
    SubmitPurchaseBillData(e, true) // Call SubmitSaleData function with isPrint as true
  }

  //Set Discount
  const [discountMode, setDiscountMode] = useState(false)
  const setDiscount = () => {
    setDiscountMode(!discountMode)
  }

  //Set Tax
  const [taxMode, setTaxMode] = useState(false)
  const setTax = () => {
    setTaxMode(!taxMode)
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <div>
                <h5 className="mt-2">View Supplier Bill</h5>
              </div>
            </Col>
            <Col xl="6">
              <div className="float-end">
                <Button
                  className="btn btn-primary"
                  color="primary"
                  onClick={EditBill}
                >
                  {EditMode ? "In Edit Mode" : "Edit"}
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Supplier Details
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={4}>
                          <div className="mb-3">
                            <Label
                              className="control-label"
                              htmlFor="supplier_name"
                            >
                              Supplier
                            </Label>
                            <InputGroup>
                              <select
                                defaultValue="0"
                                className="form-select"
                                disabled={!EditMode}
                                onChange={e =>
                                  handleChangeSupplier(
                                    e.target.selectedOptions[0]
                                  )
                                }
                                name="supplier_name"
                                value={formData.supplier_name}
                              >
                                <option value={formData.supplier_name}>
                                  {formData.supplier_name} ({supCode})
                                </option>

                                {/* {suppliers.length > 0 ? (
                                  suppliers
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option
                                        key={index}
                                        value={e.bussiness_name}
                                      >
                                        {e.bussiness_name} ({e.acc_no})
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )} */}
                              </select>
                            </InputGroup>
                          </div>
                          <div className="mb-3">
                            <label>Address</label>
                            <Input
                              className="form-control"
                              type="textarea"
                              placeholder="Address"
                              name="address_"
                              disabled={!EditMode}
                              rows="5"
                              value={formData.address_}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <Label
                              className="control-label"
                              htmlFor="project_name"
                            >
                              Project
                            </Label>
                            <InputGroup>
                              <select
                                defaultValue="0"
                                className="form-select"
                                onChange={handleChange}
                                disabled={!EditMode}
                                name="project_name"
                                value={formData.project_name}
                              >
                                <option value={formData.project_name}>
                                  {formData.project_name} ({prjCode})
                                </option>

                                {/* {projects.length > 0 ? (
                                  projects
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option
                                        key={index}
                                        value={e.project_name}
                                      >
                                        {e.project_name} ({e.project_code})
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )} */}
                              </select>
                            </InputGroup>
                          </div>
                        </Col>

                        <Col xl={4}>
                          <div className="mb-3">
                            <label>Bill No.</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="bill no."
                              disabled
                              name="bill_no"
                              value={formData.bill_no}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Date</label>
                            <Input
                              className="form-control"
                              type="date"
                              //   placeholder="Payment Mode"
                              name="date_"
                              value={formData.date_}
                              onChange={handleChange}
                              disabled={!EditMode}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Term Days</label>
                            <Input
                              className="form-control"
                              type="number"
                              min={0}
                              placeholder="0"
                              name="term_days"
                              disabled={!EditMode}
                              value={formData.term_days}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Due Date</label>
                            <Input
                              className="form-control"
                              type="date"
                              placeholder="Payment Mode"
                              name="due_date"
                              disabled={!EditMode}
                              value={formData.due_date}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>

                {/* Expense Detail  */}
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne2">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col2,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Expense Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row className="mb-3">
                        <Col xl={12}>
                          <table>
                            <thead>
                              <tr>
                                <th>Expense</th>
                                <th>{""}</th>
                                <th>{""}</th>
                                <th>Description</th>
                                <th>{""}</th>
                                <th>Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {FEdata.map(item => (
                                <tr key={item.id}>
                                  <td style={{ display: "none" }}>{item.id}</td>
                                  <td colSpan={3}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      value={item.Fexpense_}
                                      disabled={!EditMode}
                                      onChange={e =>
                                        handleEditFE(
                                          item.FEid,
                                          "Fexpense_",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>

                                      {expenses.length > 0 ? (
                                        expenses
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.expense_name}
                                            >
                                              {e.expense_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>
                                  <td colSpan={2}>
                                    <textarea
                                      type="text"
                                      disabled={!EditMode}
                                      value={item.Fexpense_desc}
                                      id={`textarea-${item.FEid}`}
                                      style={{
                                        height:
                                          textareaHeights[item.FEid] || "auto",
                                      }}
                                      onChange={e => {
                                        handleEditFE(
                                          item.FEid,
                                          "Fexpense_desc",
                                          e.target.value
                                        )
                                        handleTextareaChange(
                                          item.FEid,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.Fexpense_desc}
                                    </textarea>
                                  </td>

                                  <td>
                                    <input
                                      type="number"
                                      value={item.Fexpense_amount}
                                      disabled={!EditMode}
                                      onChange={e =>
                                        handleEditFE(
                                          item.FEid,
                                          "Fexpense_amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={
                                        EditMode
                                          ? () => deleteFetchRow(item.FEid)
                                          : undefined
                                      }
                                      style={{
                                        color: "#F46A6A",
                                        cursor: !EditMode
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tbody>
                              {Edata.map(item => (
                                <tr key={item.id}>
                                  <td style={{ display: "none" }}>{item.id}</td>
                                  <td colSpan={3}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      value={item.expense_}
                                      disabled={!EditMode}
                                      onChange={e =>
                                        handleEditE(
                                          item.id,
                                          "expense_",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>

                                      {expenses.length > 0 ? (
                                        expenses
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.expense_name}
                                            >
                                              {e.expense_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>
                                  <td colSpan={2}>
                                    <textarea
                                      type="text"
                                      disabled={!EditMode}
                                      value={item.expense_desc}
                                      id={`textarea-${item.id}`}
                                      style={{
                                        height:
                                          textareaHeights[item.id] || "auto",
                                      }}
                                      onChange={e => {
                                        handleEditE(
                                          item.id,
                                          "expense_desc",
                                          e.target.value
                                        )
                                        handleTextareaChange(
                                          item.id,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.expense_desc}
                                    </textarea>
                                  </td>

                                  <td>
                                    <input
                                      type="number"
                                      value={item.expense_amount}
                                      disabled={!EditMode}
                                      onChange={e =>
                                        handleEditE(
                                          item.id,
                                          "expense_amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={
                                        EditMode
                                          ? () => deleteERow(item.id)
                                          : undefined
                                      }
                                      style={{
                                        color: "#F46A6A",
                                        cursor: !EditMode
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                            <tbody>
                              <thead></thead>
                              <tr
                                style={{
                                  borderTop: "1px solid black",
                                }}
                              >
                                <td colSpan={3}>
                                  <strong>Total</strong>
                                </td>
                                <td colSpan={2}>
                                  <div></div>
                                </td>

                                <td>
                                  <input
                                    type="text"
                                    value={TotalExpenseAmount.toFixed(2)}
                                    disabled
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>

                      {EditMode && (
                        <Row>
                          <Col>
                            <div className="my-3">
                              <Button
                                onClick={handleAddERow}
                                color="primary"
                                className="btn btn-sm"
                              >
                                <i className="fa fa-fw fa-plus" />
                                Add Row
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Collapse>
                </div>

                {/* Product Detail  */}

                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne2">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col3,
                      })}
                      type="button"
                      onClick={t_col3}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Product Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col3} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row className="mb-3">
                        <Col xl={12}>
                          <table>
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>{""}</th>
                                <th>Description</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>Rate</th>
                                <th>Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {FPdata.map(item => (
                                <tr key={item.FPid}>
                                  <td style={{ display: "none" }}>
                                    {item.FPid}
                                  </td>
                                  <td colSpan={2}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      disabled={!EditMode}
                                      value={item.Fproduct_}
                                      onChange={e =>
                                        handleFPEdit(
                                          item.FPid,
                                          "Fproduct_",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      {products.length > 0 ? (
                                        products
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.prd_name}
                                            >
                                              {e.prd_type == "Stock"
                                                ? `${e.prd_name} (${
                                                    e.prd_qty_onhand !== null
                                                      ? Number(e.prd_qty_onhand)
                                                      : Number(0)
                                                  }) `
                                                : e.prd_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      value={item.Fprd_desc}
                                      id={`textarea2-${item.FPid}`}
                                      disabled={!EditMode}
                                      style={{
                                        height:
                                          textareaHeights2[item.FPid] || "auto",
                                      }}
                                      onChange={e => {
                                        handleFPEdit(
                                          item.FPid,
                                          "Fprd_desc",
                                          e.target.value
                                        )
                                        handleTextareaChange2(
                                          item.FPid,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.Fprd_desc}
                                    </textarea>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      disabled
                                      value={item.Fprd_unit}
                                      onChange={e =>
                                        handleFPEdit(
                                          item.FPid,
                                          "Fprd_unit",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={item.Fprd_qty}
                                      disabled={!EditMode}
                                      placeholder="0"
                                      min={1}
                                      onChange={e =>
                                        handleFPEdit(
                                          item.FPid,
                                          "Fprd_qty",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={item.Fprd_rate}
                                      min={0}
                                      placeholder="0"
                                      disabled={!EditMode}
                                      onChange={e =>
                                        handleFPEdit3(
                                          item.FPid,
                                          "Fprd_rate",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      disabled={!EditMode}
                                      value={parseFloat(
                                        item.Fprd_amount
                                      ).toFixed(2)}
                                      onChange={e =>
                                        handleFPEdit(
                                          item.FPid,
                                          "Fprd_amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={
                                        EditMode
                                          ? () => deleteFPRow(item.FPid)
                                          : undefined
                                      }
                                      style={{
                                        color: "#F46A6A",
                                        cursor: !EditMode
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tbody>
                              {data.map(item => (
                                <tr key={item.id}>
                                  <td style={{ display: "none" }}>{item.id}</td>
                                  <td colSpan={2}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      value={item.product_}
                                      onChange={e =>
                                        handleEdit(
                                          item.id,
                                          "product_",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      {products.length > 0 ? (
                                        products
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.prd_name}
                                            >
                                              {e.prd_type == "Stock"
                                                ? `${e.prd_name} (${
                                                    e.prd_qty_onhand !== null
                                                      ? Number(e.prd_qty_onhand)
                                                      : Number(0)
                                                  }) `
                                                : e.prd_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      value={item.prd_desc}
                                      id={`textarea3-${item.id}`}
                                      style={{
                                        height:
                                          textareaHeights3[item.id] || "auto",
                                      }}
                                      onChange={e => {
                                        handleEdit(
                                          item.id,
                                          "prd_desc",
                                          e.target.value
                                        )
                                        handleTextareaChange3(
                                          item.id,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.prd_desc}
                                    </textarea>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_unit}
                                      onChange={e =>
                                        handleEdit(
                                          item.id,
                                          "prd_unit",
                                          e.target.value
                                        )
                                      }
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={item.prd_qty}
                                      placeholder="0"
                                      min={1}
                                      onChange={e =>
                                        handleEdit(
                                          item.id,
                                          "prd_qty",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={item.prd_rate}
                                      placeholder="0"
                                      min={0}
                                      onChange={e =>
                                        handleEdit3(
                                          item.id,
                                          "prd_rate",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={parseFloat(
                                        item.prd_amount
                                      ).toFixed(2)}
                                      onChange={e =>
                                        handleEdit(
                                          item.id,
                                          "prd_amount",
                                          e.target.value
                                        )
                                      }
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={() => deleteRow(item.id)}
                                      style={{
                                        color: "#F46A6A",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                            <tbody>
                              <thead></thead>
                              <tr
                                style={{
                                  borderTop: "1px solid black",
                                }}
                              >
                                <td colSpan={2}>
                                  <strong>Total</strong>
                                </td>
                                <td>
                                  <div></div>
                                </td>
                                <td>
                                  <div></div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={totalQuantity}
                                    disabled
                                  />
                                </td>
                                <td>
                                  <div></div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={TotalPrdAmount.toFixed(2)}
                                    disabled
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>

                      {EditMode && (
                        <Row>
                          <Col>
                            <div className="my-3">
                              <Button
                                onClick={handleAddRow}
                                color="primary"
                                className="btn btn-sm"
                              >
                                <i className="fa fa-fw fa-plus" />
                                Add Row
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Collapse>
                </div>

                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={4}>
                        <label>Notes</label>
                        <Input
                          className="form-control"
                          type="textarea"
                          placeholder="Notes"
                          name="final_notes"
                          rows="7"
                          disabled={!EditMode}
                          value={formData.final_notes}
                          onChange={handleChange}
                        ></Input>
                      </Col>
                      <Col xl={4}></Col>
                      <Col xl={4}>
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <td className="">Sub Total</td>
                              <td className="text-end">
                                {subTotalAmount.toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Discount {""}
                                {EditMode && (
                                  <span>
                                    <i
                                      className="bx bx-edit font-size-18"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                      }}
                                      onClick={setDiscount}
                                      ref={iconRef}
                                    />
                                  </span>
                                )}
                              </td>
                              {discountMode ? (
                                <td className="text-end">
                                  <input
                                    type="number"
                                    name="discount_Amount"
                                    placeholder="0.00"
                                    value={formData.discount_Amount}
                                    onChange={handleChange}
                                    ref={iconRef}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {formData.discount_Amount !== ""
                                    ? formatAmount(formData.discount_Amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="">
                                Sale Tax (%)
                                {EditMode && (
                                  <span>
                                    <i
                                      className="bx bx-edit font-size-18"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                      }}
                                      onClick={setTax}
                                      ref={iconRef2}
                                    />
                                  </span>
                                )}
                              </td>
                              {taxMode ? (
                                <td className="text-end">
                                  <input
                                    type="number"
                                    name="tax_percentage"
                                    placeholder="%"
                                    value={formData.tax_percentage}
                                    onChange={handleChange}
                                    ref={iconRef2}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {Tax_amount !== ""
                                    ? formatAmount(Tax_amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="border-0">
                                <strong>Total</strong>
                              </td>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  {FinalTotalAmount.toFixed(2)}
                                </h4>
                              </td>
                            </tr>
                          </thead>
                        </Table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {!EditMode && (
                  <div>
                    {count && (
                      <div className="accordion-item mb-3">
                        <h2 className="accordion-header" id="headingOne3">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: !col3,
                              }
                            )}
                            type="button"
                            onClick={t_col3}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "white",
                            }}
                          >
                            Payment Details
                          </button>
                        </h2>

                        <Collapse isOpen={col3} className="accordion-collapse">
                          <div className="accordion-body">
                            <Row>
                              <div className="table-responsive">
                                <Table className="table mb-0">
                                  <thead className="table-light">
                                    <tr>
                                      <th>No</th>
                                      <th>Date</th>
                                      <th>Bank</th>
                                      <th>Ref No</th>
                                      <th>Pay By</th>
                                      <th>Pay Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {payments.length > 0 ? (
                                      payments.map((e, index) =>
                                        e.bill_amount > 0 ? (
                                          <tr key={index}>
                                            <td>
                                              <Link
                                                to={`/expenses/control/payments/view/${e.vo_id}`}
                                              >
                                                {e.vo_id}
                                              </Link>
                                            </td>
                                            <td>{e.pay_date}</td>
                                            <td>{e.bank_name}</td>
                                            <td>{e.pay_ref_no}</td>
                                            <td>{e.pay_by}</td>
                                            <td>
                                              {formatAmount(e.total_pay_amount)}
                                            </td>
                                          </tr>
                                        ) : (
                                          <></>
                                        )
                                      )
                                    ) : (
                                      <td>No Data Found</td>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Row>
                          </div>
                        </Collapse>
                      </div>
                    )}
                  </div>
                )}
                {/* <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne3">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col4,
                      })}
                      type="button"
                      onClick={t_col4}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Add Payment
                    </button>
                  </h2>

                  <Collapse isOpen={col4} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Date</label>
                            <Input
                              className="form-control"
                              type="date"
                              //   placeholder="Payment Mode"
                              name="payment_date_"
                              value={formData.payment_date_}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={3}>
                          <div className="mb-3">
                            <label className="" htmlFor="autoSizingSelect">
                              Bank
                            </label>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleChange}
                              name="bank_name"
                              value={formData.bank_name}
                            >
                              <option value={null}>Select bank ...</option>
                              <option
                                value="add_bank"
                                style={{ color: "blue" }}
                              >
                                + Add New
                              </option>
                              {bank.length > 0 ? (
                                bank
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.bank_name}>
                                      {e.bank_name}
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col xl={3}>
                          <div className="mb-3">
                            <label className="" htmlFor="autoSizingSelet">
                              Payment Mode
                            </label>
                            <select
                              className="form-select"
                              type="text"
                              value={formData.payment_mode}
                              onChange={handleChange}
                              name="payment_mode"
                            >
                              <option value="">Mode...</option>
                              <option
                                value="add_mode"
                                style={{ color: "blue" }}
                              >
                                + Add new
                              </option>
                              {pay_mode.length > 0 ? (
                                pay_mode
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.mode_name}>
                                      {e.mode_name}
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Ref No</label>
                            <Input
                              className="form-control"
                              type="text"
                              min={0}
                              placeholder="0"
                              name="payment_ref_no"
                              value={formData.payment_ref_no}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Amount</label>
                            <Input
                              className="form-control"
                              type="number"
                              min={0}
                              placeholder="0.00"
                              name="payment_amount"
                              value={formData.payment_amount}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div> */}

                <Row className="mb-3">
                  <Col xl={4}>
                    <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Col>
                  {EditMode ? (
                    <Col xl={8}>
                      <div className="d-flex flex-wrap gap-2 float-end">
                        <Link
                          to="#"
                          onClick={handleSaveAndClose}
                          className="btn btn-primary  me-2"
                        >
                          Save Edit & Close
                        </Link>
                      </div>
                      <div className="d-flex flex-wrap gap-2 float-end">
                        <Link
                          to="#"
                          onClick={handleSaveAndPrint}
                          className="btn btn-success  me-2"
                        >
                          Save Edit & Print
                        </Link>
                      </div>
                    </Col>
                  ) : (
                    <Col xl={8}>
                      <div className="d-flex flex-wrap gap-2 float-end">
                        <Link
                          to="#"
                          onClick={handleEmail}
                          className="btn btn-primary  me-2"
                        >
                          <i className="mdi mdi-email" /> Send Email
                        </Link>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <div>
          <SocialModal
            isOpen={socialModal}
            toggle={toggleModal}
            EmailMode={EmailMode}
            ModalMailData={ModalMailData}
            handleModalMailData={handleModalMailData}
            SendEmail={SendEmail}
            pdfUrl={pdfUrl}
            setModalMailData={setModalMailData}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewPurchaseBill
