import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "../Purchaces/PurchaceData"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { use } from "i18next"
import TableContainer from "components/Common/TableContainer"
import apiUrl from "config"

const Setting = props => {
  //meta title
  document.title = "Setting"

  const [mode, setMode] = useState("")
  const [category, setCategory] = useState("")
  const [nominal_acc, setNominal_acc] = useState("")
  const [nominal_acc_code, setNominal_acc_code] = useState("")
  const [expense_name, setExpense_name] = useState("")
  const [expense_code, setExpense_code] = useState("")
  const [unit, setUnit] = useState("")

  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)
  const [col5, setcol5] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(false)
  }
  const t_col2 = () => {
    setcol1(false)
    setcol2(!col2)
    setcol3(false)
    setcol4(false)
    setcol5(false)
  }
  const t_col3 = () => {
    setcol1(false)
    setcol2(false)
    setcol3(!col3)
    setcol4(false)
    setcol5(false)
  }
  const t_col4 = () => {
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(!col4)
    setcol5(false)
  }
  const t_col5 = () => {
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(!col5)
  }

  const handleMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const modedata = new FormData()
    modedata.append("mode_name", mode)
    modedata.append("app_method", "payment_mode")
    if (mode === "") {
      toastr.error("Mode name is Required")
    } else {
      axios
        .post(URL, modedata)
        .then(response => {
          if (response.data === "success") {
            setMode("")
            toastr.success("Add Successfully")
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const handleNlAcc = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const addNlAcc = new FormData()
    addNlAcc.append("nominal_acc_name", nominal_acc)
    addNlAcc.append("nominal_acc_code", nominal_acc_code)
    addNlAcc.append("app_method", "add_nom_acc")
    if (nominal_acc === "") {
      toastr.error("Nominal Account Name is Required")
    } else if (nominal_acc_code === "") {
      toastr.error("Nominal Account Code is Required ")
    } else {
      axios
        .post(URL, addNlAcc)
        .then(response => {
          if (response.data === "success") {
            setNominal_acc("")
            setNominal_acc_code("")
            toastr.success("Add Successfully")
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }

  const handleExpense = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const addExpense = new FormData()
    addExpense.append("expense_name", expense_name)
    addExpense.append("expense_code", expense_code)
    addExpense.append("app_method", "add_expense_detail")
    if (expense_name === "") {
      toastr.error("Expense Name is Required")
    } else {
      axios
        .post(URL, addExpense)
        .then(response => {
          if (response.data === "success") {
            setExpense_name("")
            setExpense_code("")
            toastr.success("Add Successfully")
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const handleCategory = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const addCategory = new FormData()
    addCategory.append("category_name", category)
    addCategory.append("app_method", "add_product_cat")
    if (category === "") {
      toastr.error("Category Name is Required")
    } else {
      axios
        .post(URL, addCategory)
        .then(response => {
          if (response.data === "success") {
            setCategory("")
            toastr.success("Add Successfully")
          } else {
            console.log("Error in add Categpry")
            toastr.error("Error in Adding Category")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const handleUnit = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const unitData = new FormData()
    unitData.append("unit_name", unit)
    unitData.append("app_method", "add_unit")
    if (unit === "") {
      toastr.error("Unit Name is Required")
    } else {
      axios
        .post(URL, unitData)
        .then(response => {
          if (response.data === "success") {
            setUnit("")
            toastr.success("Unit Add Successfully")
          } else {
            toastr.error("Error in Adding Unit")
          }
        })
        .catch(error => console.log(error))
    }
  }

  const expandAll = () => {
    setcol1(!col1)
    setcol2(!col2)
    setcol3(!col3)
    setcol4(!col4)
    setcol5(!col5)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="8">
              <div>
                <h5 className="mt-2">Setting</h5>
              </div>
            </Col>

            <Col xl="4">
              <div className="float-end d-flex gap-2">
                <Link to={"/setting/view_parameters"}>
                  <Button className="btn-sm btn btn-primary" color="primary">
                    View Parameters
                  </Button>
                </Link>

                <Button className="btn-sm btn btn-success" onClick={expandAll}>
                  Expand All
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col lg="6">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Add Payment Mode
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={8}>
                          <div className="mb-3">
                            <label>Payment Mode</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Payment Mode"
                              name="payment_mode"
                              value={mode}
                              onChange={e => setMode(e.target.value)}
                            ></Input>
                            <div className="mt-2">
                              <label></label>
                              <Button
                                color="primary"
                                onClick={handleMode}
                                className="btn btn-sm"
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Col>
                        {/* <Col xl={4}>
                            <div className="float-end">
                              <Button
                                className="btn btn-sm"
                                color="success"
                                onClick={viewpaymentmodes}
                              >
                                {" "}
                                view
                              </Button>
                            </div>
                          </Col> */}
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne2">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col2,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Add Nominal Account
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={8}>
                          <div className="mb-3">
                            <label>Nominal Account</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Nominal Account"
                              name="nominal_acc"
                              value={nominal_acc}
                              onChange={e => setNominal_acc(e.target.value)}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Account Code</label>
                            <Input
                              className="form-control"
                              type="number"
                              placeholder="Nominal Account Code"
                              name="nominal_acc"
                              value={nominal_acc_code}
                              onChange={e =>
                                setNominal_acc_code(e.target.value)
                              }
                            ></Input>
                          </div>
                          <div className="mt-2">
                            <label></label>
                            <Button
                              color="primary"
                              className="btn btn-sm"
                              onClick={handleNlAcc}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                        {/* <Col xl={4}>
                            <div className="float-end">
                              <Button
                                className="btn btn-sm"
                                color="success"
                                onClick={viewnominalaccount}
                              >
                                {" "}
                                view
                              </Button>
                            </div>
                          </Col> */}
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne3">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col3,
                      })}
                      type="button"
                      onClick={t_col3}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Add Expense Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col3} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={8}>
                          <div className="mb-3">
                            <label>Expense Name</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="name"
                              name="expense_name"
                              value={expense_name}
                              onChange={e => setExpense_name(e.target.value)}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Expense Code</label>
                            <Input
                              className="form-control"
                              type="number"
                              placeholder="code"
                              name="expense_code"
                              value={expense_code}
                              onChange={e => setExpense_code(e.target.value)}
                            ></Input>
                          </div>
                          <div className="mt-2">
                            <label></label>
                            <Button
                              color="primary"
                              className="btn btn-sm"
                              onClick={handleExpense}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                        {/* <Col xl={4}>
                            <div className="float-end">
                              <Button className="btn btn-sm" color="success">
                                {" "}
                                view
                              </Button>
                            </div>
                          </Col> */}
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col4,
                      })}
                      type="button"
                      onClick={t_col4}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Add Products Category
                    </button>
                  </h2>

                  <Collapse isOpen={col4} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={8}>
                          <div className="mb-3">
                            <label>Category Name</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Category Name"
                              name="category_name"
                              value={category}
                              onChange={e => setCategory(e.target.value)}
                            ></Input>
                            <div className="mt-2">
                              <label></label>
                              <Button
                                color="primary"
                                onClick={handleCategory}
                                className="btn btn-sm"
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Col>
                        {/* <Col xl={4}>
                            <div className="float-end">
                              <Button className="btn btn-sm" color="success">
                                {" "}
                                view
                              </Button>
                            </div>
                          </Col> */}
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col5,
                      })}
                      type="button"
                      onClick={t_col5}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Add Weight Unit
                    </button>
                  </h2>

                  <Collapse isOpen={col5} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={8}>
                          <div className="mb-3">
                            <label>Weight Unit</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Unit Name"
                              name="weight_unit"
                              value={unit}
                              onChange={e => setUnit(e.target.value)}
                            ></Input>
                            <div className="mt-2">
                              <label></label>
                              <Button
                                color="primary"
                                onClick={handleUnit}
                                className="btn btn-sm"
                              >
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Col>
                        {/* <Col xl={4}>
                            <div className="float-end">
                              <Button className="btn btn-sm" color="success">
                                {" "}
                                view
                              </Button>
                            </div>
                          </Col> */}
                      </Row>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Setting
