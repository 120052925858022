import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
  Row,
} from "reactstrap"

import { Pdate, Value, Coin } from "./BankAccountData"

import TableContainer from "../../components/Common/TableContainer"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import SocialModal from "components/SocialControl/Social"
function formatAmountCurrency(amount, currency) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  })
}

const BankPayments = props => {
  //meta title
  document.title = "Bank Payments"

  const [orders, setOrders] = useState([])
  const [productData, setSetProductData] = useState([orders])

  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setloading] = useState(false)

  const [socialModal, setSocialModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")
  //Modal Mail Data
  const [ModalMailData, setModalMailData] = useState({
    from_mail: "",
    to_mail: "",
    mail_subj: "",
    email_message:
      "<p>Please find attached confirmation for your Order.<br>Thank you for your business.<br>Best Regards.<br><strong>Front Force</strong> ",
  })
  const handleModalMailData = e => {
    const { name, value } = e.target
    setModalMailData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  //Eamil
  const [EmailMode, setEmailMode] = useState(false)
  const handleEmail = id => {
    const data = new FormData()
    data.append("id", id)
    data.append("app_method", "fetch_payment_detail")
    const URL = `${apiUrl}/BankManagemnt/viewBankdetail.php`
    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSocialModal(!socialModal)
          setPdfUrl(response.data[0].pdf_url)
          setEmailMode(true)
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  const SendEmail = () => {
    if (ModalMailData.to_mail === "") {
      toastr.error("Sent Email is Required")
    } else {
      const data = new FormData()
      data.append("from_mail", ModalMailData.from_mail)
      data.append("to_mail", ModalMailData.to_mail)
      data.append("mail_subj", ModalMailData.mail_subj)
      data.append("email_message", ModalMailData.email_message)
      data.append("pdf_url", pdfUrl)
      data.append("app_method", "send_email")
      const URL = `${apiUrl}/socialaccounts/emailhandle.php`
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.message === "success") {
            toastr.success("Email Sent Successfully")
            setSocialModal(!socialModal)
            setModalMailData(prevData => ({
              ...prevData,
              to_mail: "",
              mail_subj: "",
            }))
          } else {
            // Handle case where response.data is not an array
            console.error("Response data is not an array.")
          }
        })
        .catch(error => console.log(error))
    }
  }
  //Toggle
  const toggleModal = () => {
    setSocialModal(!socialModal)
  }

  const fetchPayments = async () => {
    setOrders([])
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_payments")

    try {
      setloading(true)
      const response = await axios.post(URL, Pdata)
      const data = Array.isArray(response.data) ? response.data : []
      setOrders(data)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  //Fetch Payment onClick

  useEffect(() => {
    fetchPayments()
  }, [])
  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "V.No",
        accessor: "trans_id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Link to={`/bank/control/bank_payment/view/${cellProps.value}`}>
              {cellProps.value}
            </Link>
          )
        },
      },
      {
        Header: "Date",
        accessor: "trans_date",
        disableFilters: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },
      {
        Header: "Bank",
        accessor: "bank_name",
        disableFilters: true,

        Cell: cellProps => {
          const bankName = cellProps.value
          const bankCode = cellProps.row.original.bank_code
          return (
            <>
              {bankName} ({bankCode})
            </>
          )
        },
      },
      {
        Header: "Nominal Account",
        accessor: "trans_nl_acc",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Ref. No",
        accessor: "trans_ref_no",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Detail",
        accessor: "trans_detail",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Amount",
        accessor: "trans_amount",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <p>{formatAmountCurrency(parseFloat(cellProps.value), "PKR")}</p>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action_id",
        disableFilters: true,
        Cell: cellProps => {
          const viewId = cellProps.row.original.trans_id
          return (
            <div className="d-flex gap-2">
              <Link className="text-primary">
                <i
                  className="mdi mdi-email font-size-18"
                  id="viewtooltip11"
                  onClick={() => handleEmail(viewId)}
                />
                <UncontrolledTooltip placement="top" target="viewtooltip11">
                  Email
                </UncontrolledTooltip>
              </Link>
              <Link
                to={`/bank/control/bank_payment/view/${viewId}`}
                className="text-primary"
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  deleteConfirmation(
                    cellProps.row.original.trans_id,
                    cellProps.row.original.total_amount,
                    cellProps.row.original.bank_name,
                    cellProps.row.original.project_name
                  )
                }}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip2"
                />
                <UncontrolledTooltip placement="top" target="deletetooltip2">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  const [deletedata, setDeletedata] = useState([])

  const deleteConfirmation = (id, amount, bank, project) => {
    setDeletedata({
      id: id,
      amount: amount,
      bank: bank,
      project: project,
    })
    setDeleteModal(true)
  }
  const deletePayment = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const data = new FormData()
    data.append("del_id", deletedata.id)
    data.append("Tamount", deletedata.amount)
    data.append("bank_name", deletedata.bank)
    data.append("project_name", deletedata.project)
    data.append("app_method", "delete_payment")
    axios
      .post(URL, data)
      .then(response => {
        if (response.data === "success") {
          setDeleteModal(false)
          toastr.success("Patyment Delete Sucessfully")
          fetchPayments()
        } else {
          setDeleteModal(false)
          toastr.error("Error in delete payment")
        }
      })
      .catch(error => console.log(error))
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={deletePayment}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="9">
              <div>
                <h5 className="mt-2">Bank Payments</h5>
              </div>
            </Col>
            <Col xl="3">
              <div className="d-flex gap-2 flex-wrap float-end">
                <Link to="/bank/control/bank_payments/b_pay/add">
                  <Button className="btn btn-sm" color="primary">
                    <i className="fa fa-fw fa-plus" />
                    Add New {""}
                  </Button>
                </Link>

                <Button className="btn btn-sm btn-light">
                  <i
                    className="mdi mdi-file-import font-size-12"
                    id="importtooltip"
                  />
                  <UncontrolledTooltip placement="top" target="importtooltip">
                    Import
                  </UncontrolledTooltip>
                </Button>
                <Button className="btn btn-sm btn-danger">
                  <i
                    className="mdi mdi-delete font-size-12"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    // isAddOptions={true}
                    // handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <SocialModal
          isOpen={socialModal}
          toggle={toggleModal}
          EmailMode={EmailMode}
          ModalMailData={ModalMailData}
          handleModalMailData={handleModalMailData}
          SendEmail={SendEmail}
          pdfUrl={pdfUrl}
          setModalMailData={setModalMailData}
        />
      </div>
    </React.Fragment>
  )
}

export default BankPayments
