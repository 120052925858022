import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import "assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "./PurchaceData"

import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"
import axios from "axios"
import SocialModal from "components/SocialControl/Social"

const PurchaseBill = props => {
  //meta title
  document.title = "Purchase Bills"
  const navigate = useNavigate()
  const [orders, SetOrders] = useState([])

  const [deleteModal, setDeleteModal] = useState(false)

  const [loading, setloading] = useState(false)

  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  const [socialModal, setSocialModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")

  //Modal Mail Data
  const [ModalMailData, setModalMailData] = useState({
    from_mail: "",
    to_mail: "",
    mail_subj: "",
    email_message:
      "<p>Please find attached confirmation for your Order.<br>Thank you for your business.<br>Best Regards.<br><strong>Front Force</strong> ",
  })
  const handleModalMailData = e => {
    const { name, value } = e.target
    setModalMailData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  //Eamil
  const [EmailMode, setEmailMode] = useState(false)
  const handleEmail = id => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const post = new FormData()
    post.append("pur_id", id)
    post.append("app_method", "fetch_bill_det_byId")
    axios
      .post(URL, post)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSocialModal(!socialModal)
          setPdfUrl(response.data[0].pdf_url)
          setWhatsAppMode(false)
          setEmailMode(true)
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  const SendEmail = () => {
    if (ModalMailData.to_mail === "") {
      toastr.error("Sent Email is Required")
    } else {
      const data = new FormData()
      data.append("from_mail", ModalMailData.from_mail)
      data.append("to_mail", ModalMailData.to_mail)
      data.append("mail_subj", ModalMailData.mail_subj)
      data.append("email_message", ModalMailData.email_message)
      data.append("pdf_url", pdfUrl)
      data.append("app_method", "send_email")
      const URL = `${apiUrl}/socialaccounts/emailhandle.php`
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.message === "success") {
            toastr.success("Email Sent Successfully")
            setSocialModal(!socialModal)
          } else {
            // Handle case where response.data is not an array
            console.error("Response data is not an array.")
          }
        })
        .catch(error => console.log(error))
    }
  }

  //Toggle
  const toggleModal = () => {
    setSocialModal(!socialModal)
  }

  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "Bill ID",
        accessor: "purchase_id",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "created_date",
        disableFilters: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },
      {
        Header: "A/C No",
        accessor: "acc_no",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Supplier",
        accessor: "supplier_name",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Project",
        accessor: "project_name",
        disableFilters: true,
        Cell: cellProps => {
          const code = cellProps.row.original.project_code
          return (
            <>
              {cellProps.value} ({code})
            </>
          )
        },
      },
      {
        Header: "Type",
        accessor: "bill_type",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Bill No",
        accessor: "purchase_bill_no",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Total",
        accessor: "total_amount",
        disableFilters: true,
        Cell: cellProps => {
          const total = Number(cellProps.value).toFixed(2)
          return <>{total}</>
        },
      },
      {
        Header: "Balance",
        accessor: "balance_",
        disableFilters: true,
        Cell: cellProps => {
          const balance = Number(cellProps.value).toFixed(2)
          return <>{balance}</>
        },
      },
      {
        Header: "Action",
        accessor: "ID2",
        disableFilters: true,
        Cell: cellProps => {
          const purchase_id = cellProps.row.original.purchase_id
          const supplier = cellProps.row.original.supplier_name
          const status = cellProps.row.original.payment_status
          const project = cellProps.row.original.project_name
          return (
            <div className="d-flex gap-2">
              {status !== "Paid" && (
                <Link
                  to={{
                    pathname: `/expenses/control/bills/make_payment/${purchase_id}`,
                  }}
                  state={{ supplier: supplier, project: project }}
                  className="text-primary"
                >
                  <i
                    className="mdi mdi-currency-usd font-size-18"
                    id="paymenttooltip"
                  />
                  <UncontrolledTooltip placement="top" target="paymenttooltip">
                    Make Payment
                  </UncontrolledTooltip>
                </Link>
              )}
              <Link className="text-primary">
                <i
                  className="mdi mdi-email font-size-18"
                  id="viewtooltip11"
                  onClick={() => handleEmail(purchase_id)}
                />
                <UncontrolledTooltip placement="top" target="viewtooltip11">
                  Email
                </UncontrolledTooltip>
              </Link>

              <Link
                to={{
                  pathname: `/expenses/control/bills/view/${purchase_id}`,
                  state: { supplier },
                }}
                className="text-primary"
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip2" />
                <UncontrolledTooltip placement="top" target="viewtooltip2">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  deleteBill(purchase_id)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const deleteBill = id => {
    setDeleteModal(true)
  }
  const handleDeleteBill = () => {
    fetchPaymentsBill()
    toastr.error("Paid Bills/credits can't be deleted")
    setDeleteModal(false)
  }

  const fetchPaymentsBill = async () => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const data = new FormData()
    data.append("app_method", "fetch_payments_bills")
    try {
      setloading(true)
      const response = await axios.post(URL, data)
      const data6 = Array.isArray(response.data) ? response.data : []
      SetOrders(data6)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    fetchPaymentsBill()
  }, [])

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBill}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="9">
              <div>
                <h5 className="mt-2">Supplier Bills</h5>
              </div>
            </Col>
            <Col xl="3">
              <div className="d-flex gap-2 flex-wrap float-end">
                <Link to="/expenses/control/bills/add">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md btn btn-sm"
                  >
                    <i className="fa fa-fw fa-plus" />
                    Add New
                  </Button>
                </Link>

                <Button className="btn btn-sm btn-light">
                  <i
                    className="mdi mdi-file-import font-size-12"
                    id="importtooltip"
                  />
                  <UncontrolledTooltip placement="top" target="importtooltip">
                    Import
                  </UncontrolledTooltip>
                </Button>
                <Button className="btn btn-sm btn-danger">
                  <i
                    className="mdi mdi-delete font-size-12"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    // isAddOptions={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <SocialModal
          isOpen={socialModal}
          toggle={toggleModal}
          EmailMode={EmailMode}
          ModalMailData={ModalMailData}
          handleModalMailData={handleModalMailData}
          SendEmail={SendEmail}
          pdfUrl={pdfUrl}
          setModalMailData={setModalMailData}
        />
      </div>
    </React.Fragment>
  )
}

export default PurchaseBill
