import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  UncontrolledTooltip,
  Row,
} from "reactstrap"

import { Pdate, Value } from "./BankAccountData"

import TableContainer from "../../components/Common/TableContainer"
import axios from "axios"
import DeleteModal from "components/Common/DeleteModal"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import SocialModal from "components/SocialControl/Social"

function formatAmountCurrency(amount, currency) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  })
}

const Transfers = props => {
  //meta title
  document.title = "Bank Transfers"

  const [orders, setOrders] = useState([])
  const [accountModal, setAccountModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setloading] = useState(false)

  const [socialModal, setSocialModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")
  //Modal Mail Data
  const [ModalMailData, setModalMailData] = useState({
    from_mail: "",
    to_mail: "",
    mail_subj: "",
    email_message:
      "<p>Please find attached confirmation for your Order.<br>Thank you for your business.<br>Best Regards.<br><strong>Front Force</strong> ",
  })
  const handleModalMailData = e => {
    const { name, value } = e.target
    setModalMailData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  //Eamil
  const [EmailMode, setEmailMode] = useState(false)
  const handleEmail = id => {
    const data = new FormData()
    data.append("id", id)
    data.append("app_method", "fetch_payment_detail")
    const URL = `${apiUrl}/BankManagemnt/viewBankTransfer.php`
    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSocialModal(!socialModal)
          setPdfUrl(response.data[0].pdf_url)
          setEmailMode(true)
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  const SendEmail = () => {
    if (ModalMailData.to_mail === "") {
      toastr.error("Sent Email is Required")
    } else {
      const data = new FormData()
      data.append("from_mail", ModalMailData.from_mail)
      data.append("to_mail", ModalMailData.to_mail)
      data.append("mail_subj", ModalMailData.mail_subj)
      data.append("email_message", ModalMailData.email_message)
      data.append("pdf_url", pdfUrl)
      data.append("app_method", "send_email")
      const URL = `${apiUrl}/socialaccounts/emailhandle.php`
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.message === "success") {
            toastr.success("Email Sent Successfully")
            setSocialModal(!socialModal)
            setModalMailData(prevData => ({
              ...prevData,
              to_mail: "",
              mail_subj: "",
            }))
          } else {
            // Handle case where response.data is not an array
            console.error("Response data is not an array.")
          }
        })
        .catch(error => console.log(error))
    }
  }
  //Toggle
  const toggleModal = () => {
    setSocialModal(!socialModal)
  }

  const fetchTransfers = async () => {
    setOrders([])
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_transfers")

    try {
      setloading(true)
      const response = await axios.post(URL, Pdata)
      const data = Array.isArray(response.data) ? response.data : []
      setOrders(data)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    fetchTransfers()
  }, [])
  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "V.No",
        accessor: "trans_id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Link to={`/bank/control/bank_transfer/view/${cellProps.value}`}>
              {cellProps.value}
            </Link>
          )
        },
      },
      {
        Header: "Date",
        accessor: "trans_date",
        disableFilters: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },
      {
        Header: "From Bank",
        accessor: "from_bank",
        disableFilters: true,
        Cell: cellProps => {
          const bankName = cellProps.value
          const bankCode = cellProps.row.original.from_bank_code
          return (
            <>
              {bankName} ({bankCode})
            </>
          )
        },
      },
      {
        Header: "To Bank",
        accessor: "to_bank",
        disableFilters: true,
        Cell: cellProps => {
          const bankName = cellProps.value
          const bankCode = cellProps.row.original.to_bank_code
          return (
            <>
              {bankName} ({bankCode})
            </>
          )
        },
      },
      {
        Header: "Ref. No",
        accessor: "trans_ref_no",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Amount",
        accessor: "trans_amount",
        disableFilters: true,
        Cell: cellProps => {
          return <>{formatAmountCurrency(parseFloat(cellProps.value), "PKR")}</>
        },
      },
      {
        Header: "Action",
        accessor: "action_id",
        disableFilters: true,
        Cell: cellProps => {
          const id = cellProps.row.original.trans_id
          return (
            <div className="d-flex gap-2">
              <Link className="text-primary">
                <i
                  className="mdi mdi-email font-size-18"
                  id="viewtooltip11"
                  onClick={() => handleEmail(id)}
                />
                <UncontrolledTooltip placement="top" target="viewtooltip11">
                  Email
                </UncontrolledTooltip>
              </Link>
              <Link
                to={`/bank/control/bank_transfer/view/${id}`}
                className="text-primary"
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  deleteConfirmation(
                    cellProps.row.original.trans_id,
                    cellProps.row.original.trans_amount,
                    cellProps.row.original.from_bank,
                    cellProps.row.original.to_bank
                  )
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const [deletedata, setDeletedata] = useState([])

  const deleteConfirmation = (id, amount, Sbank, Rbank) => {
    setDeletedata({
      id: id,
      amount: amount,
      Sbank: Sbank,
      Rbank: Rbank,
    })
    setDeleteModal(true)
  }

  const deleteTransfer = () => {
    const formData2 = {
      method: "delete_transfer",
      del_id: deletedata.id,
      TRamount: deletedata.amount,
      from_bank: deletedata.Sbank,
      to_bank: deletedata.Rbank,
    }
    axios
      .post(`${apiUrl}/BankManagemnt/addbank.php`, formData2)
      .then(response => {
        if (response.data === "success") {
          setDeleteModal(false)
          toastr.success("Transfer Delete Sucessfully")
          fetchTransfers()
        } else {
          setDeleteModal(false)
          toastr.error("Error in delete Transfer")
        }
      })
      .catch(error => console.log(error))
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={deleteTransfer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="9">
              <div>
                <h5 className="mt-2">Transfers</h5>
              </div>
            </Col>
            <Col xl="3">
              <div className="d-flex gap-2 flex-wrap float-end">
                {/* <Dropdown
                  isOpen={alllocation}
                  className="dropdown-mega d-none d-lg-block ms-2"
                  toggle={() => setAlllocation(!alllocation)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-sm btn-primary px-3 font-size-13"
                  >
                    Add New
                    <span>
                      <i className="mdi mdi-chevron-down" />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link to="/bank/control/bank_payments/b_pay/add">
                      <DropdownItem>Bank Payment</DropdownItem>
                    </Link>
                    <DropdownItem>WHT Payment</DropdownItem>
                  </DropdownMenu>
                </Dropdown> */}
                <Link to="/bank/control/bank_transfer/b_trans/add">
                  <Button className="btn btn-sm" color="primary">
                    <i className="fa fa-fw fa-plus" />
                    Add New {""}
                  </Button>
                </Link>

                <Button className="btn btn-sm btn-light">
                  <i
                    className="mdi mdi-file-import font-size-12"
                    id="importtooltip"
                  />
                  <UncontrolledTooltip placement="top" target="importtooltip">
                    Import
                  </UncontrolledTooltip>
                </Button>
                <Button className="btn btn-sm btn-danger">
                  <i
                    className="mdi mdi-delete font-size-12"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <Form>
                    <Row className="mb-2">
                      <Col sm={6} className="col-xl">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Data Range</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={selectCoin}
                            onChange={e => {
                              setselectCoin(e.target.value)
                            }}
                          >
                            <option value="Bitcoin">Admin</option>
                            <option value="Ethereum">Manager</option>
                            <option value="Litecoin">Member</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col sm={6} className="col-xl">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Bank</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={selectStatus}
                            onChange={e => {
                              setselectStatus(e.target.value)
                            }}
                          >
                            <option value="Completed">Select..</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col sm={6} className="col-xl">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Nominal A/C</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={selectStatus}
                            onChange={e => {
                              setselectStatus(e.target.value)
                            }}
                          >
                            <option value="Pending">user</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col xl="2" sm={6} className="col-xl align-self-end ">
                        <div className="mb-3">
                          <Button
                            type="button"
                            color="primary"
                            className="w-md"
                            onClick={() => handleFilter()}
                          >
                            Filter
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form> */}
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    // isAddOptions={true}
                    // handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <SocialModal
          isOpen={socialModal}
          toggle={toggleModal}
          EmailMode={EmailMode}
          ModalMailData={ModalMailData}
          handleModalMailData={handleModalMailData}
          SendEmail={SendEmail}
          pdfUrl={pdfUrl}
          setModalMailData={setModalMailData}
        />
      </div>
    </React.Fragment>
  )
}

export default Transfers
