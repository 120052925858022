import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const AccountReport = props => {
  //meta title
  document.title = "Report"

  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)
  const [col5, setcol5] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(false)
  }
  const t_col2 = () => {
    setcol1(false)
    setcol2(!col2)
    setcol3(false)
    setcol4(false)
    setcol5(false)
  }
  const t_col4 = () => {
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(!col4)
    setcol5(false)
  }
  const t_col5 = () => {
    setcol1(false)
    setcol2(false)
    setcol3(false)
    setcol4(false)
    setcol5(!col5)
  }
  // Table Data

  const expandall = () => {
    setcol1(!col1)
    setcol2(!col2)
    setcol3(!col3)
    setcol4(!col4)
    setcol5(!col5)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Reports</h5>
              </div>
            </Col>
            <Col xl="2">
              <div className="float-end">
                <Button className="btn-sm btn btn-success" onClick={expandall}>
                  Expand All
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col lg="6">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne2">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col2,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Bank Details
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={6}>
                          <div className="table-responsive">
                            <Table className="table-nowrap">
                              <tbody>
                                <tr>
                                  <td>001</td>
                                  <td>
                                    <Link to="/reports/bank_detail/bank_payment">
                                      {"Bank Payment"}
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td>002</td>
                                  <td>
                                    <Link to="/reports/bank_detail/bank_receipt">
                                      {"Bank Receipt"}
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="table-responsive">
                            <Table className="table-nowrap">
                              <tbody>
                                <tr>
                                  <td>003</td>
                                  <td>
                                    <Link to="/reports/bank_detail/bank_transfer">
                                      {"Bank Transfer"}
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Sales and Customer's Report
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={6}>
                          <div className="table-responsive">
                            <Table className="table-nowrap">
                              <tbody>
                                <tr>
                                  <td>001</td>
                                  <td>
                                    <Link to="/reports/sales/quotations">
                                      {"Quotations"}
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <i className="bx bx-star" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>002</td>
                                  <td>
                                    <Link to="/reports/sales/invoices">
                                      {"Sale Invoices"}
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <i className="bx bx-star" />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="table-responsive">
                            <Table className="table-nowrap">
                              <tbody>
                                <tr>
                                  <td>003</td>
                                  <td>
                                    <Link to="/reports/sales/customers">
                                      {"Customer Report"}
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <i className="bx bx-star" />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col4,
                      })}
                      type="button"
                      onClick={t_col4}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Purchase and Supplier's Report
                    </button>
                  </h2>

                  <Collapse isOpen={col4} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={6}>
                          <div className="table-responsive">
                            <Table className="table-nowrap">
                              <tbody>
                                <tr>
                                  <td>001</td>
                                  <td>
                                    <Link to="/reports/purchases/bills">
                                      {"Purchase Bills"}
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <i className="bx bx-star" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>002</td>
                                  <td>
                                    <Link to="/reports/purchases/payments">
                                      {"Purchase Payments"}
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <i className="bx bx-star" />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="table-responsive">
                            <Table className="table-nowrap">
                              <tbody>
                                <tr>
                                  <td>003</td>
                                  <td>
                                    <Link to="/reports/purchases/suppliers">
                                      {"Supplier's Report"}
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <i className="bx bx-star" />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col5,
                      })}
                      type="button"
                      onClick={t_col5}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Projects Reports
                    </button>
                  </h2>

                  <Collapse isOpen={col5} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={6}>
                          <div className="table-responsive">
                            <Table className="table-nowrap">
                              <tbody>
                                <tr>
                                  <td>001</td>
                                  <td>
                                    <Link to="/report/projects/transactions">
                                      {"Projects Transactions"}
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <i className="bx bx-star" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>002</td>
                                  <td>
                                    <Link to="/report/projects/invoices">
                                      {"Projects Invoices"}
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <i className="bx bx-star" />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="table-responsive">
                            <Table className="table-nowrap">
                              <tbody>
                                <tr>
                                  <td>003</td>
                                  <td>
                                    <Link to="/report/projects/bills">
                                      {"Projects Bill's"}
                                    </Link>
                                  </td>
                                  <td className="text-end">
                                    <i className="bx bx-star" />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AccountReport
